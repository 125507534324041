import React from 'react';
import {Step} from '../../../Common/Types/Steps.types';
import './styles.scss';

interface IStepIndicator {
  step: Step,
  className?: string
}

const StepIndicator: React.FC<IStepIndicator> = (props) => {

  const {
    step,
    className
  } = props;

  return(
    <div className={`stepIndicator ${className}`}>
      {step.stepNumber}/{step.numberOfSteps}
    </div>
  )
};

export default StepIndicator;
