import React, {ChangeEvent, MouseEvent, useState, useCallback, useEffect, useRef} from 'react';
import {Input} from 'reactstrap';
import {IInputProps, IInputSelection} from '../../../Common/Types/Input.types';
import {IAccentedComponent} from '../../../Common/Types/Common.types';
import {Step} from '../../../Common/Types/Steps.types';
import StepIndicator from "../../Elements/StepIndicator";
import InputHeader from "../../Elements/InputHeader";
import { ReactComponent as ChevronSVG } from '../../../Common/Assets/chevron-black.svg';
import './styles.scss';

export type DropdownSelectionValueType = string;

export interface IDropdownOption extends IInputSelection<DropdownSelectionValueType> {
}

interface IDropdownSelection extends IInputProps<IDropdownOption>, IAccentedComponent {
  options: IDropdownOption[],
  formStep?: Step
}

const DropdownSelection: React.FC<IDropdownSelection> = (props) => {

  const dropdownRef = useRef<HTMLDivElement>(null);

  const {
    name,
    value,
    label,
    labelClassName,
    placeholder,
    onChange,
    disabled,
    formStep,
    accentColor,
    options
  } = props;

  const [textValue, setTextValue] = useState<DropdownSelectionValueType>("");
  const [dropdownToggle, setDropdownToggle] = useState<boolean>(false);
  const [showAllOptions, setShowAllOptions] = useState(true);

  useEffect(() => {
    if(value) {
      setTextValue(value.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDropdown = useCallback((value?: boolean) => {
    if (disabled) {
      return
    }

    setDropdownToggle((prevState) => value !== undefined ? value : !prevState)
  }, [disabled]);

  useEffect(() => {

    const handleClickOutside = (ev: MouseEvent) => {
      if(!dropdownRef || !dropdownRef.current)
        return;

      // @ts-ignore
      if(!dropdownRef.current.contains(ev.target)){
        toggleDropdown(false);
        setShowAllOptions(true);
      }
    };

    // @ts-ignore
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // @ts-ignore
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [toggleDropdown]);

  const handleTextInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if(showAllOptions) // To prevent needless re-renders
      setShowAllOptions(false);

    const textValue = e.target.value;

    setTextValue(textValue);
  };

  const handleOptionSelectClick = (selectedOption: IDropdownOption) => {
    onChange({value: selectedOption, name });
    setTextValue(selectedOption.label);

    toggleDropdown(false);
    setShowAllOptions(true);
  };

  const renderOptions = () => {
    let optionsToRender: IDropdownOption[];
    if(textValue && !showAllOptions) {

      optionsToRender = options.filter(option => {
        const optionText = option.label.toUpperCase();
        const searchText = textValue.toUpperCase();

        return optionText.indexOf(searchText) !== -1
      });
    } else {
      optionsToRender = [...options];
      const indexOfSelected = optionsToRender.indexOf(value);
      if(indexOfSelected > -1) {
        optionsToRender.splice(indexOfSelected, 1);
        optionsToRender = [value, ...optionsToRender]
      }
    }

    return(
      <div className={`optionsContainer`}>
        <div className='dropdownOptions'>
          {
            optionsToRender.map((option, index) =>
              <div
                className={`dropdownOption cursorPointer ${accentColor}`}
                key={`${index}${value}`}
                onClick={() => handleOptionSelectClick(option)}
              >
                {option.icon && <div className='optionIconContainer'><img src={option.icon} alt={option.label} className='fluidSvg'/></div>}
                {option.label}
              </div>
            )
          }
        </div>
      </div>
    )
  };

  return(
    <div className='dropdownSelection mvpInput' ref={dropdownRef}>
      {label && <InputHeader label={label} labelClassName={labelClassName}/>}
      <div
        className={`inputWrapper d-flex flex-row cursorPointer ${accentColor}`}
        onClick={() => toggleDropdown()}
      >
        <Input
          value={textValue}
          type='text'
          placeholder={placeholder}
          onChange={handleTextInputChange}
        />
        <div className='chevron'>
          <ChevronSVG className={`fluidSvg ${!dropdownToggle && 'rotate-180'}`}/>
        </div>
        {formStep && <StepIndicator step={formStep}/>}
      </div>

      {dropdownToggle && renderOptions()}
    </div>
  )
};

export default DropdownSelection;
