import { combineReducers } from 'redux';
import wizardStepsReducer from './WizardSteps/wizardSteps.reducer'
import wizardReducer from "./Wizard/wizard.reducer";
import loadingReducer from './Loading/loading.reducer';
import {reducer as toastrReducer} from "react-redux-toastr";

const rootReducer = combineReducers({
  wizardSteps: wizardStepsReducer,
  wizard: wizardReducer,
  loading: loadingReducer,
  toastr: toastrReducer
});

export default rootReducer;
