import React from 'react'
import './styles.scss'

const Loader = () => {
  return (
    <div className='d-flex justify-content-center align-items-center loading'>
      <div className='loader'/>
    </div>
  )
};

export default Loader
