export enum ACCENT_COLOR {
  PURPLE = 'purpleAccent',
  CYAN = 'cyanAccent',
  WHITE = 'whiteAccent',
  RED = 'redAccent',
  BLUE = 'blueAccent',
  GRAY = 'grayAccent'
}

export interface IAccentedComponent {
  accentColor: ACCENT_COLOR
}

export interface ICustomClassComponent {
  className?: string;
}
