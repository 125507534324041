import React, {useEffect, useRef, useState} from 'react';
import {BrowserRouterProps, useHistory} from 'react-router-dom';
import Page from "../../Elements/Page";
import WizardHeader from "../../Components/WizardHeader";
import TextInput from '../../Components/TextInput';
import Button from "../../Elements/Button";
import {ReactComponent as ChevronGray} from "../../../Common/Assets/chevron-gray.svg";
import {ReactComponent as ChevronWhite} from "../../../Common/Assets/chevron-white.svg";
import {ReactComponent as RestartIcon} from "../../../Common/Assets/icn-restart.svg";
import {ACCENT_COLOR} from "../../../Common/Types/Common.types";
import {Routes} from "../index";
import {useDispatch, useSelector} from "react-redux";
import {clearWizardState, sendDbmcPdf, generateDbmcPdf} from "../../../Core/Redux/Wizard/wizard.actions";
import {clearWizardStepsState} from "../../../Core/Redux/WizardSteps/wizardSteps.actions";
import ProgressBar from "../../Elements/ProgressBar";
import {createLoadingSelector} from "../../../Core/Redux/Loading/loading.reducer";
import {GENERATE_DBMC_PDF_REQUEST, SEND_DBMC_PDF_REQUEST, GENERATE_TC_PDF_REQUEST} from "../../../Core/Redux/Wizard/wizard.types";
import {AppState} from "../../../Core/Redux/store";
import './styles.scss'
import {isEmailValid} from "../../../Core/Utils/validator.util";
import ReCAPTCHA from 'react-google-recaptcha';
import {REACT_APP_RECAPTCHA_SITE_KEY} from '../../../Common/envConfig';

const generatingDbmcPdfSelector = createLoadingSelector([
  GENERATE_DBMC_PDF_REQUEST
]);

const generatingTcPdfSelector = createLoadingSelector([
  GENERATE_TC_PDF_REQUEST
]);

const sendingDbmcPdfSelector = createLoadingSelector([
  SEND_DBMC_PDF_REQUEST
]);

const WizardBonusStepPage: React.FC<BrowserRouterProps> = () => {

  let recaptchaRef = useRef<ReCAPTCHA>(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const [dbmcProgress, setDbmcProgress] = useState(0);
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState();

  const isGeneratingDbmcPdf = useSelector((state: AppState) => generatingDbmcPdfSelector(state));
  const isGeneratingTcPdf = useSelector((state: AppState) => generatingTcPdfSelector(state));
  const isSendingDbmcPdf = useSelector((state: AppState) => sendingDbmcPdfSelector(state));

  const [isValidatingCaptcha, setIsValidatingCaptcha] = useState(false);
  const [isCaptchaExpired, setIsCaptchaExpired] = useState(true);

  //Start pdf generation after tc pdf is generated, because of bug inside react-pdf preventing as multiple simultaneous pdf renders
  useEffect(() => {
    if(!isGeneratingTcPdf)
      dispatch(generateDbmcPdf());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGeneratingTcPdf]);

  useEffect(() => {
    function sleep(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    async function progressInterval() {
      if(dbmcProgress<80) {
        //This here is artificial 2s loader, 0.5s * 80/20 = 2s
        await sleep(500);
        setDbmcProgress(prevState => prevState + 20)
      } else if(isGeneratingDbmcPdf) {
        //When artificially done if still actually generating increase progress by bits, we dont know actual progress
        await sleep(250);
        setDbmcProgress(prevState => (100-prevState) / 3);
      } else {
        //If generation was completed while artificial loader, simply set it to 100 and continue with the program
        setDbmcProgress(100);
      }
    }

    progressInterval().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbmcProgress]);

  const handleWizardReset = () => {
    dispatch(clearWizardState());
    dispatch(clearWizardStepsState());
    history.replace(Routes.CANVAS_WIZARD);
  };

  const handleSendMailBtn = () => {
    // We validate the email address
    if(!isEmailValid(email)) {
      setErrMsg("You have entered an invalid email address.");
      return;
    }
    setErrMsg("");

    //If it's fine we start executing the captcha
    executeCaptcha();
  };

  const executeCaptcha = () => {
    if(recaptchaRef && recaptchaRef.current) { // If gcaptcha is loaded into the browser start validation
      if(isCaptchaExpired) {
        setIsValidatingCaptcha(true); // Set state to disable email input to prevent any change
        recaptchaRef.current.execute();
      } else {
        sendMail(); // If our token is still valid just send the mail
      }
    }
  };

  const sendMail = () => {
    // @ts-ignore
    const recaptchaToken = recaptchaRef.current.getValue() as string;
    dispatch(sendDbmcPdf(email, recaptchaToken));
  };

  const onCaptchaValidated = () => { // When gcaptcha callback returns response, it means it's passed
    sendMail();
    setIsValidatingCaptcha(false); // Validation is complete
    setIsCaptchaExpired(false);
  };

  const onCaptchaError = () => {
    setIsValidatingCaptcha(false);
  };

  const onCaptchaExpiration = () => {
    setIsCaptchaExpired(true);
  };

  return(
    <Page fullPage={true} className='wizardDonePage p-0'>
      <WizardHeader/>
      <div className='wizardDoneContainer'>
        <span className='heroHeadline'>Congrats!</span>
        <span className='descriptionText'>
          Your Decentralized Business Model Canvas (DBMC) is being automagically generated, so that you can share it
          with your team, friends, or whomever and keep refining it. since this is just the starting point for your
          amazing project. Now - to which email address should we send the DBMC to?
        </span>

        <div className='emailInput'>
          <TextInput
            label="Email Address"
            placeholder="example@email.com"
            value={email}
            name='emailInput'
            errorMsg={errMsg}
            onChange={(callbackValue) => setEmail(callbackValue.value)}
            accentColor={ACCENT_COLOR.BLUE}
            disabled={isValidatingCaptcha || isSendingDbmcPdf}
          />
          <ProgressBar percent={dbmcProgress} accentColor={ACCENT_COLOR.BLUE}/>

        </div>

        <div className='stepBtnContainer justify-content-between align-self-stretch'>
          <Button
            onClick={handleWizardReset}
            accentColor={ACCENT_COLOR.WHITE}
            label="Create DBMC From Scratch"
            leftComponent={<RestartIcon/>}
            labelClassName='font-weight-bold'
          />
          {
            dbmcProgress !== 100 ?
              <Button
                onClick={() => {}}
                label={`Almost Complete ${dbmcProgress}%...`}
                labelClassName='font-weight-bold'
                className='finishBtn'
                rightComponent={<ChevronGray className='rotate-180'/>}
              />
              :
              <Button
                onClick={handleSendMailBtn}
                label="Email Me My DBMC"
                labelClassName='font-weight-bold'
                accentColor={ACCENT_COLOR.BLUE}
                rightComponent={<ChevronWhite/>}
                disabled={email.length <= 0}
                loading={isSendingDbmcPdf}
              />
          }
        </div>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={REACT_APP_RECAPTCHA_SITE_KEY as string}
        onChange={onCaptchaValidated}
        onErrored={onCaptchaError}
        onExpired={onCaptchaExpiration}
      />
    </Page>
  )
};

export default WizardBonusStepPage;
