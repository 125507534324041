import React, { Fragment, useEffect, useState } from 'react';
import ProgressBar from '../../Elements/ProgressBar';
import TextInput from '../TextInput';
import DropdownSelection from '../DropdownSelection';
import ButtonSelection from '../ButtonSelection';
import { IAccentedComponent } from '../../../Common/Types/Common.types';
import { EFormType, EGeneralInfoSteps, Step } from '../../../Common/Types/Steps.types';
import { industryList, tokenOption } from '../../../Common/Constants/Canvas.constants';
import { getFormType, handleFormInputChange } from '../../../Core/Utils/common.util';
import GeneralInfoModel, { GeneralInfoInputs } from '../../../Core/Models/GeneralInfo.model';
import { nextStep, prevStep } from '../../../Core/Redux/WizardSteps/wizardSteps.actions';
import { setGeneralInfoForm } from '../../../Core/Redux/Wizard/wizard.actions';
import { useDispatch, useSelector } from 'react-redux'
import { InputChange, InputsByStep } from '../../../Common/Types/Input.types';
import { AppState } from '../../../Core/Redux/store';
import WizardNavigation from '../../Elements/WizardNavigation';
import './styles.scss';
import WizardSidebar from '../WizardSidebar';
import { useXlScreenListener } from '../../../Core/Utils/hooks.util';
import { hasValue } from '../../../Core/Utils/validator.util';

interface IGeneralInfoFormProps extends IAccentedComponent{
  formStep: Step
}

const inputByStep: InputsByStep<EGeneralInfoSteps, GeneralInfoInputs> = {
  [EGeneralInfoSteps.PROJECT_NAME]: 'projectName',
  [EGeneralInfoSteps.SELECT_INDUSTRY]: 'industry',
  [EGeneralInfoSteps.DESCRIBE_PROJECT]: 'description',
  [EGeneralInfoSteps.DESCRIBE_BM]: 'businessModel',
  [EGeneralInfoSteps.TOKEN_PLANNED]: 'isThereToken'
};

const GeneralInfoForm: React.FC<IGeneralInfoFormProps> = (props) => {

  const dispatch = useDispatch();

  const [formState, setFormState] = useState<GeneralInfoInputs>(new GeneralInfoModel().getInputs);
  const reduxGeneralInfoForm = useSelector((state: AppState) => state.wizard.generalInfoForm);
  const isXlScreen = useXlScreenListener();
  const [requiredError, setRequiredError] = useState(false);

  const {
    formStep,
    accentColor
  } = props;

  useEffect(() => {
    if(reduxGeneralInfoForm) {
      setFormState(reduxGeneralInfoForm)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrevious = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    if (getFormType(formStep.name) !== EFormType.GENERAL_INFO_FORM) {
      return;
    }

    const currentInputName = inputByStep[formStep.name as EGeneralInfoSteps];

    if (hasValue(formState[currentInputName].value)) {
      setRequiredError(false);
    } else {
      setRequiredError(true);
    }
  }, [formState, formStep]);

  const handleNext = () => {
    if(formStep.stepNumber === formStep.numberOfSteps) {
      dispatch(setGeneralInfoForm(formState));
    }
    dispatch(nextStep());
  };

  const handleInputChange = (callback: InputChange<any>) => {
    handleFormInputChange(setFormState, callback);
  };

  const renderSteps = () => {
    switch (formStep.name) {
      case EGeneralInfoSteps.PROJECT_NAME:
        return(
          <TextInput
            type="textarea"
            name={formState.projectName.name}
            value={formState.projectName.value}
            onChange={handleInputChange}
            label="Your Project's Name"
            placeholder="Insert an awesome product or service here"
            formStep={formStep}
            accentColor={accentColor}
          />
        );
      case EGeneralInfoSteps.SELECT_INDUSTRY:
        return(
          <DropdownSelection
            options={industryList}
            name={formState.industry.name}
            value={formState.industry.value}
            onChange={handleInputChange}
            label="Select your industry"
            placeholder="Choose from the list or type your own"
            formStep={formStep}
            accentColor={accentColor}
          />
        );
      case EGeneralInfoSteps.DESCRIBE_PROJECT:
        return(
          <TextInput
            type="textarea"
            name={formState.description.name}
            value={formState.description.value}
            onChange={handleInputChange}
            label="Describe Your Project"
            placeholder="Short description of your project"
            formStep={formStep}
            accentColor={accentColor}
          />
        );
      case EGeneralInfoSteps.DESCRIBE_BM:
        return(
          <TextInput
            type="textarea"
            name={formState.businessModel.name}
            value={formState.businessModel.value}
            onChange={handleInputChange}
            label="How Would You Describe Your Business Model?"
            placeholder="Example - focus on the value streams you provide"
            formStep={formStep}
            accentColor={accentColor}
          />
        );
      case EGeneralInfoSteps.TOKEN_PLANNED:
        return(
          <ButtonSelection
            options={tokenOption}
            name={formState.isThereToken.name}
            value={formState.isThereToken.value}
            onChange={handleInputChange}
            label="Do You Plan To Have a Token?"
            formStep={formStep}
            accentColor={accentColor}
          />
        )
    }
  };

  const progressPercent = formStep.stepNumber / formStep.numberOfSteps * 100;

  return(
      <Fragment>
        <div className='generalInfoForm'>
          <div className='formContainer'>

            <div className='inputContainer'>
              {renderSteps()}
              <ProgressBar percent={progressPercent} accentColor={accentColor}/>
            </div>

            {isXlScreen && <WizardNavigation
              formStep={formStep}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              accentColor={accentColor}
              nextDisabled={requiredError}
            />}
          </div>
        </div>
        {
          !isXlScreen && // If not desktop show the sidebar component here
          <Fragment>
            <WizardSidebar
              accentColor={accentColor}
            />
            <WizardNavigation
              formStep={formStep}
              handlePrevious={() => {}}
              handleNext={handleNext}
              accentColor={accentColor}
              nextDisabled={requiredError}
            />
          </Fragment>
        }
      </Fragment>
  )
};

export default GeneralInfoForm;
