import React from 'react';

interface IInputHeader {
  label: string,
  errorMsg?: string,
  labelClassName?: string
}

const InputHeader: React.FC<IInputHeader> = (props) => {

  const {
    label,
    errorMsg,
    labelClassName
  } = props;

  return(
    <h4 className={`label ${labelClassName}`}>
      {label}
      {errorMsg && <span className='errorMsg'>{errorMsg}</span>}
    </h4>
  )
};

export default InputHeader;
