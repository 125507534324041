const {
  REACT_APP_INFURA_IPFS_API_URL,
  REACT_APP_INFURA_IPFS_API_VERSION,
  REACT_APP_EMAILJS_API_URL,
  REACT_APP_EMAILJS_API_VERSION,
  REACT_APP_EMAILJS_USER_ID,
  REACT_APP_EMAILJS_SERVICE_ID,
  REACT_APP_EMAILJS_DBMC_TEMPLATE_ID,
  REACT_APP_RECAPTCHA_SITE_KEY
} = process.env;

if(!REACT_APP_INFURA_IPFS_API_URL) {
  throw Error("Please provide [REACT_APP_INFURA_IPFS_API_URL] in .env");
}

if(!REACT_APP_INFURA_IPFS_API_VERSION) {
  throw Error("Please provide [REACT_APP_INFURA_IPFS_API_VERSION] in .env");
}

if(!REACT_APP_EMAILJS_API_URL) {
  throw Error("Please provide [REACT_APP_EMAILJS_API_URL] in .env");
}

if(!REACT_APP_EMAILJS_API_VERSION) {
  throw Error("Please provide [REACT_APP_EMAILJS_API_URL] in .env");
}

if(!REACT_APP_EMAILJS_USER_ID) {
  throw Error("Please provide [REACT_APP_EMAILJS_USER_ID] in .env");
}

if(!REACT_APP_EMAILJS_SERVICE_ID) {
  throw Error("Please provide [REACT_APP_EMAILJS_SERVICE_ID] in .env");
}

if(!REACT_APP_EMAILJS_DBMC_TEMPLATE_ID) {
  throw Error("Please provide [REACT_APP_EMAILJS_DBMC_TEMPLATE_ID] in .env");
}

if(!REACT_APP_RECAPTCHA_SITE_KEY) {
  throw Error("Please provide [REACT_APP_RECAPTCHA_SITE_KEY] in .env");
}

export {
  REACT_APP_INFURA_IPFS_API_URL,
  REACT_APP_INFURA_IPFS_API_VERSION,
  REACT_APP_EMAILJS_API_URL,
  REACT_APP_EMAILJS_API_VERSION,
  REACT_APP_EMAILJS_USER_ID,
  REACT_APP_EMAILJS_SERVICE_ID,
  REACT_APP_EMAILJS_DBMC_TEMPLATE_ID,
  REACT_APP_RECAPTCHA_SITE_KEY
}
