import {
  EFormType,
  EGeneralInfoSteps,
  ECanvasSteps
} from '../../Common/Types/Steps.types';
import {CanvasStepsList, GeneralInfoStepsList} from '../../Common/Constants/Steps.constants';
import {Dispatch, SetStateAction} from 'react';
import {InputChange} from '../../Common/Types/Input.types';

export function padNumber(n: number) {
  if (n < 10) {
    return '0' + n;
  }
  return n;
}

export function getFormType(stepName: EGeneralInfoSteps | ECanvasSteps) {

  // @ts-ignore
  if(GeneralInfoStepsList.indexOf(stepName) !== -1)
    return EFormType.GENERAL_INFO_FORM;

  // @ts-ignore
  if(CanvasStepsList.indexOf(stepName) !== -1)
    return EFormType.CANVAS_FORM;
}

export function handleFormInputChange<T>(hookHandler: Dispatch<SetStateAction<T>>, onChangeCallback: InputChange<any>) {
  const {
    name,
    value
  } = onChangeCallback;

  hookHandler(prevState => ({
    ...prevState,
    [name]: {
      ...prevState[name as keyof T],
      value
    }
  }))
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise<string>(async (resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = (ev) => {
      if(ev.target) {
        const base64String = ev.target.result as string;
        resolve(base64String)
      } else {
        reject();
      }
    };
    reader.onerror = () => {
      reader.abort();
      reject()
    };

    reader.readAsDataURL(blob);
  })
}
