import {ECanvasSteps, EGeneralInfoSteps, StepTip, WizardStep} from "../Types/Steps.types";

export const GeneralInfoStepsList = [
  EGeneralInfoSteps.PROJECT_NAME,
  EGeneralInfoSteps.SELECT_INDUSTRY,
  EGeneralInfoSteps.DESCRIBE_PROJECT,
  EGeneralInfoSteps.DESCRIBE_BM,
  EGeneralInfoSteps.TOKEN_PLANNED
];

export const CanvasStepsList = [
  ECanvasSteps.TARGET_CUSTOMERS,
  ECanvasSteps.VALUE_PROPOSITION,
  ECanvasSteps.PROPOSED_SOLUTION,
  ECanvasSteps.REACHING_TRUST,
  ECanvasSteps.VALIDATOR_INCENTIVE,
  ECanvasSteps.INTERACTION_CHANNELS,
  ECanvasSteps.NETWORK_GOVERNANCE,
  ECanvasSteps.COST_STRUCTURE,
  ECanvasSteps.REVENUE_STREAMS
];

export const stepLabels = {
  [EGeneralInfoSteps.PROJECT_NAME]: "Project's Name",
  [EGeneralInfoSteps.SELECT_INDUSTRY]: "Choose the Industry",
  [EGeneralInfoSteps.DESCRIBE_PROJECT]: "About Your Project",
  [EGeneralInfoSteps.DESCRIBE_BM]: "Business Model",
  [EGeneralInfoSteps.TOKEN_PLANNED]: "Token Or Not?",
  [ECanvasSteps.TARGET_CUSTOMERS]: "User Segments",
  [ECanvasSteps.VALUE_PROPOSITION]: "Value Proposition",
  [ECanvasSteps.PROPOSED_SOLUTION]: "Proposed Solution",
  [ECanvasSteps.REACHING_TRUST]: "Reaching Trust",
  [ECanvasSteps.VALIDATOR_INCENTIVE]: "Validator Incentive",
  [ECanvasSteps.INTERACTION_CHANNELS]: "Interaction Channels",
  [ECanvasSteps.NETWORK_GOVERNANCE]: "Network Governance",
  [ECanvasSteps.COST_STRUCTURE]: "Cost Structure",
  [ECanvasSteps.REVENUE_STREAMS]: "Revenue Streams"
};

export const stepTips: {[key: string]: StepTip} = {
  [EGeneralInfoSteps.PROJECT_NAME]: {
    tipTitle: 'Name Your Project',
    tips: [
      'The name of your project needs to resonate with and be understood by mostly everyone - you are going global after all. It should encapsulate the core purpose or goal of your project.',
      'That said, you might want to go through the canvas before deciding on the name. A good alternative is to put your mission statement as a placeholder, as it will remind you what you\'re all about.',
      'You got this :)'
    ],
    additionalLink: {
      link: 'https://namelix.com/',
      label: 'Try Out Name Generator →'
    }
  },
  [EGeneralInfoSteps.SELECT_INDUSTRY]: {
    tipTitle: 'Know Your Industry',
    tips: [
      'Most of the people who successfully start their own business have already had relevant business experience before they start, most often as employees.',
      'Regardless, we are here to guide you trough this. This should be pretty straightforward, although your product or service might be applicable across a whole range of industries.',
      'Pick the one you deem as a priority, or just pick one at random. You can (and probably will) go back and through everything several times based on your conclusions - iterations are key.'
    ]
  },
  [EGeneralInfoSteps.DESCRIBE_PROJECT]: {
    tipTitle: 'What Is Your Project About',
    tips: [
      'A well written description of any project makes it possible for the intended audience to understand the concept and context of it.',
      'A good starting point are your project\'s mission and vision, since they together describe your purpose, whom and how you serve, as well what you want to become and change in the world.',
      'You can also start with your values. Apart from what your project actually accomplishes, your values are what your users will understand and identify with when it comes to your brand image.'
    ]
  },
  [EGeneralInfoSteps.DESCRIBE_BM]: {
    tipTitle: '(Decentralized) Business Model',
    tips: [
      'Innovative (and all other successful) business models go beyond a simple formula. The mindset here should be to define a value proposition which solves a certain pain point for your target customer(s).',
      'Be aware of your problem and solution spaces, as well as your product-market fit. This will help you separate the value you wish to provide from it\'s technical implementation.',
      'You are basically trying to put all of your hard work, ideas, and the holistic overview of your product into a few sentences. It\'s not easy, but easy is not why you\'re here'
    ]
  },
  [EGeneralInfoSteps.TOKEN_PLANNED]: {
    tipTitle: 'Are You Token To Me?',
    tips: [
      'Gamers may be accustomed to token-based payments, but a platform selling groceries may have a much tougher time implementing such a payment system due to user base characteristics.',
      'You should also think about what exactly would you need your token for, i.e. how and why would you need such an instrument to represent or transfer value throughout your product\'s ecosystem.',
      'Whatever you choose to do, remember that none of this is final, and you should and will go back and think many of these segments over until everything fits - your business model needs to be sustainable after all.'
    ]
  },
  [ECanvasSteps.TARGET_CUSTOMERS]: {
    tipTitle: 'User/Customer Segments',
    tipSubtitle: 'Who are we building it for?',
    tips: [
      'Your goal here is to define the user personas for your product or service, meaning at least your primary and secondary target groups, to whom you need to provide real and attainable value.',
      'A good tool to use here if you\'re stuck is the Product/Market Fit Canvas, which will make properly map user\'s pain points and goals they wish to achieve, all in the context of your product or service.',
      'Another useful tool is the Value Proposition Canvas, which will give you a rather clear customer profile for each of your target groups. Do your best to emulate the mindset of the people whose lives you are trying to improve.'
    ]
  },
  [ECanvasSteps.VALUE_PROPOSITION]: {
    tipTitle: 'Value Proposition',
    tipSubtitle: 'Value you promise to deliver',
    tips: [
      'This is probably one of the key points in the process of defining your business model, since you need to formulate your offer (value streams) in the terms of what would actually work in the real world to facilitate your project goals.',
      'Your goal is to find the one key value proposition that defines your project, and map out all the subsequent ones around it, forming an outline of your product\'s ecosystem.',
      'Feel free to go back to your user personas and other canvases for defining value propositions if you have any doubts. Don\'t worry to much about the form you write in as long as your point is clear and unequivocal.'
    ]
  },
  [ECanvasSteps.PROPOSED_SOLUTION]: {
    tipTitle: 'Proposed Solution',
    tipSubtitle: 'Let\'s get into something concrete',
    tips: [
      'As you can see, we have entered the solution realm. Without getting too technical, describe your solution and how it functions in the sense of jobs to be done.',
      'This part of your business model will later naturally develop into user stories, which you can take into account in broad terms right now to help define what your solution looks like in the real world.',
      'A useful tool to limit your thinking to the most important (valuable) aspects of your solution is the MVP Features Matrix - focus on the Build It Now and Fake It quadrants.'
    ]
  },
  [ECanvasSteps.REACHING_TRUST]: {
    tipTitle: 'Reaching Trust',
    tipSubtitle: 'Be true to thyself',
    tips: [
      'Trust is key, especially in decentralized systems. So, the question is - what mechanisms will you use to reach, grow and maintain trust among your user base and stakeholders?',
      'When we talk about success for products on blockchain, we primarily mean - mainstream adoption. Trust is a key factor for achieving this, and products that do it well will enjoy the spoils and become the golden standard.',
      'Good UX practices will definitely help bridge the gap, but think about initiatives (educational or otherwise) that can advance your user growth and, at the end of the day, brand image.'
    ]
  },
  [ECanvasSteps.VALIDATOR_INCENTIVE]: {
    tipTitle: 'Validator Incentive',
    tipSubtitle: 'Building the network',
    tips: [
      'What are the incentives for validators, or stakeholders in general? Since your ecosystem is (at least partly) decentralized, stakeholders become extremely important compared to their role(s) in traditional business models.',
      'Incentivize the stakeholders to work in the best interest of your product or service, thus establishing stable and balanced modes of behavior for all actors in your ecosystem. Self-governance is not easy to achieve, but brings great benefits for both users and your brand.',
      'Don\'t forget to check, re-check, align and possibly redefine certain value streams for your stakeholders so your platform can grow in a sustainable way.'
    ]
  },
  [ECanvasSteps.INTERACTION_CHANNELS]: {
    tipTitle: 'Interaction Channels',
    tipSubtitle: 'Communication is important',
    tips: [
      'What are the best channels for communicating with your users, stakeholders, and the community as a whole? Do you need different approaches, in both delivery and content, for different user personas?',
      'Think about choosing the proper communication platform for each of your user personas, and make sure you have a regular update schedule. Mapping a taxonomy of your product will help you focus on content categories that are most relevant for your users and stakeholders.'
    ]
  },
  [ECanvasSteps.NETWORK_GOVERNANCE]: {
    tipTitle: 'Network Governance',
    tipSubtitle: 'Making decisions',
    tips: [
      'Blockchain-based organizations depend on governance models to exist, grow and evolve. Balancing this to ensure stability and allow for flexibility over time is not easy, but if it was everybody would be doing it.',
      'How are decisions made within the network? What is the initial set of network rules? How are Smart Contracts utilized? Who decides on future changes in network operations? What is the voting system?',
      'Depending on your product architecture and business goals, you need to define how the network will be governed in order to be able to grow sustainably, which is very much in line and tied to the Validator Incentive segment.'
    ]
  },
  [ECanvasSteps.COST_STRUCTURE]: {
    tipTitle: 'Cost Structure',
    tipSubtitle: 'Let\'s break it down',
    tips: [
      'What are the key costs of doing business? Think primarily about your capital expenses for setting everything up or expanding your scope (CAPEX), and operational expenses meaning your day-to-day spend on a monthly level (OPEX).',
      'Be mindful of what parameters you take into account when making these (and all other) assumptions. Challenge them often and don\'t be afraid to break some things in order to build them back up better than before.',
      'Also, consult different sources and people with experience if you are in doubt - at the end of the day, it\'s all about the money and your product able to grow in a lean way.'
    ]
  },
  [ECanvasSteps.REVENUE_STREAMS]: {
    tipTitle: 'Revenue Streams',
    tipSubtitle: 'Let\'s break it down',
    tips: [
      'What are the key ways to generate revenue? Answering this question is closely tied to the previously mapped value streams, as well as both incentivizing stakeholders and actually making enough profit at the end of the day for future growth.',
      'We won’t be going into the details here, but be aware not to rely entirely on best practices that currently exist, since blockchain as a technology does offer ways to disrupt many traditional revenue stream models.',
      'We will mention some useful tools which you can use to (roughly) estimate your financials - the income statement, NPV, CAGR, ROI, WACC and IRR. When in doubt - consult Investopedia.'
    ]
  }
};

export const allSteps = [...GeneralInfoStepsList, ...CanvasStepsList];

export const dbmcWizardSteps: WizardStep[] = [
  {
    label: "General Info",
    endLabel: "Let the Magic Begin!",
    steps: GeneralInfoStepsList.map((item, index) => ({
      name: item,
      numberOfSteps: GeneralInfoStepsList.length,
      stepNumber: index + 1,
      isFinished: false
    }))
  },
  {
    label: "Build Canvas",
    beginLabel: "Back to General Info",
    endLabel: "Do The Magic!",
    steps: CanvasStepsList.map((item, index) => ({
      name: item,
      numberOfSteps: CanvasStepsList.length,
      stepNumber: index + 1,
      isFinished: false
    }))
  }
];
