import {IDropdownOption} from "../../UI/Components/DropdownSelection";
import {IButtonSelectionOption} from '../../UI/Components/ButtonSelection';
import {INDUSTRY_LIST, TOKEN_SELECTION} from "../Types/Canvas.types";

export const industryList: IDropdownOption[] = [
  {
    label: 'Blockchain & Crypto',
    value: INDUSTRY_LIST.BLOCKCHAIN_AND_CRYPTO,
    icon: require('../Assets/IndustriesIcons/blockchain_crypto.svg')
  },
  {
    label: 'Banking and Financial Services',
    value: INDUSTRY_LIST.BANKING_AND_FINANCIAL,
    icon: require('../Assets/IndustriesIcons/banking_financial.svg')
  },
  {
    label: 'Real Estate',
    value: INDUSTRY_LIST.REAL_ESTATE,
    icon: require('../Assets/IndustriesIcons/real_estate.svg')
  },
  {
    label: 'Supply Chain & Logistics',
    value: INDUSTRY_LIST.SUPPLY_CHAIN_LOGISTICS,
    icon: require('../Assets/IndustriesIcons/supply_chain_logistics.svg')
  },
  {
    label: 'Intellectual Property',
    value: INDUSTRY_LIST.INTELLECTUAL_PROPERTY,
    icon: require('../Assets/IndustriesIcons/intellectual_property.svg')
  },
  {
    label: 'Medical & Healthcare',
    value: INDUSTRY_LIST.MEDICAL_AND_HEALTHCARE,
    icon: require('../Assets/IndustriesIcons/medical_healthcare.svg')
  },
  {
    label: 'Education',
    value: INDUSTRY_LIST.EDUCATION,
    icon: require('../Assets/IndustriesIcons/education.svg')
  },
  {
    label: 'Privacy & Security',
    value: INDUSTRY_LIST.PRIVACY_AND_SECURITY,
    icon: require('../Assets/IndustriesIcons/privacy_security.svg')
  },
  {
    label: 'Gaming & Esports',
    value: INDUSTRY_LIST.GAMING_AND_ESPORTS,
    icon: require('../Assets/IndustriesIcons/gaming_esports.svg')
  },
  {
    label: 'Media & Entertainment',
    value: INDUSTRY_LIST.MEDIA_AND_ENTERTAINMENT,
    icon: require('../Assets/IndustriesIcons/media_entertainment.svg')
  },
  {
    label: 'Travel & Tourism',
    value: INDUSTRY_LIST.TRAVEL_AND_TOURISM,
    icon: require('../Assets/IndustriesIcons/travel_tourism.svg')
  },
  {
    label: 'Other',
    value: INDUSTRY_LIST.OTHER,
    icon: require('../Assets/IndustriesIcons/other.svg')
  }
];

export const tokenOption: IButtonSelectionOption[] = [
  {
    label: 'Yes, I plan to have a token.',
    value: TOKEN_SELECTION.TOKEN_PLANNED,
    icon: require('../Assets/TokenSelectionIcons/icn-token.svg')
  },
  {
    label: 'No token for me.',
    value: TOKEN_SELECTION.NO_TOKEN_PLANNED,
    icon: require('../Assets/TokenSelectionIcons/icn-no_token.svg')
  }
];

