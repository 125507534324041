import {Reducer} from 'redux';
import {
  CLEAR_WIZARD_STATE,
  GENERATE_DBMC_PDF_SUCCESS,
  GENERATE_TC_PDF_SUCCESS,
  SET_CANVAS_FORM,
  SET_GENERAL_INFO_FORM,
  WizardActionTypes,
  WizardState,
} from './wizard.types';

const initialState: WizardState = {
  generalInfoForm: undefined,
  canvasForm: undefined,
  dbmcPdfBlob: undefined,
  tcPdfBlob: undefined
};

export const wizardReducer: Reducer<WizardState, WizardActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case SET_GENERAL_INFO_FORM:{
      return {
        ...state,
        generalInfoForm: action.payload.generalInfoForm
      }
    }
    case SET_CANVAS_FORM: {
      return {
        ...state,
        canvasForm: action.payload.canvasForm
      }
    }
    case GENERATE_DBMC_PDF_SUCCESS: {
      return {
        ...state,
        dbmcPdfBlob: action.payload.dbmcPdfBlob
      }
    }
    case GENERATE_TC_PDF_SUCCESS: {
      return {
        ...state,
        tcPdfBlob: action.payload.tcPdfBlob
      }
    }
    case CLEAR_WIZARD_STATE: {
      return {
        ...initialState
      }
    }

    default: {
      return state;
    }
  }
};

export default wizardReducer;
