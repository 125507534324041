import React, {ChangeEvent} from 'react';
import {Input} from 'reactstrap';
import StepIndicator from '../../Elements/StepIndicator';
import {IInputProps} from '../../../Common/Types/Input.types';
import {IAccentedComponent} from '../../../Common/Types/Common.types';
import {Step} from '../../../Common/Types/Steps.types';
import InputHeader from "../../Elements/InputHeader";
import './styles.scss';

export type TextInputValueType = string;

interface ITextInputProps extends IInputProps<TextInputValueType>, IAccentedComponent {
  type?: 'text' | 'number' | 'textarea',
  formStep?: Step
}

const textInput: React.FC<ITextInputProps> = (props) => {

  const {
    name,
    label,
    placeholder,
    value,
    onChange,
    type = 'text',
    formStep,
    accentColor,
    labelClassName,
    inputClassName,
    errorMsg,
    ...otherProps
  } = props;

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    if(!onChange)
      return;

    onChange({
      name: name,
      value: e.target.value
    })
  };

  return(

    <div className={`textInput mvpInput`}>
      {label && <InputHeader label={label} labelClassName={labelClassName} errorMsg={errorMsg}/>}
      <div className={`inputWrapper d-flex flex-row ${accentColor} ${errorMsg && 'error'}`}>
        <Input
          value={value}
          type={type}
          className={`${inputClassName}`}
          placeholder={placeholder}
          onChange={handleTextChange}
          autoFocus={true}
          {...otherProps}
        />
        {formStep && <StepIndicator step={formStep}/>}
      </div>

    </div>
  )
};

export default textInput;
