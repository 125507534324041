export enum EGeneralInfoSteps {
  PROJECT_NAME = 'PROJECT_NAME',
  SELECT_INDUSTRY = 'SELECT_INDUSTRY',
  DESCRIBE_PROJECT = 'DESCRIBE_PROJECT',
  DESCRIBE_BM = 'DESCRIBE_BM',
  TOKEN_PLANNED = 'TOKEN_PLANNED'
}

export enum ECanvasSteps {
  TARGET_CUSTOMERS = 'TARGET_CUSTOMERS',
  VALUE_PROPOSITION = 'VALUE_PROPOSITION',
  PROPOSED_SOLUTION = 'PROPOSED_SOLUTION',
  REACHING_TRUST = 'REACHING_TRUST',
  VALIDATOR_INCENTIVE = 'VALIDATOR_INCENTIVE',
  INTERACTION_CHANNELS = 'INTERACTION_CHANNELS',
  NETWORK_GOVERNANCE = 'NETWORK_GOVERNANCE',
  COST_STRUCTURE = 'COST_STRUCTURE',
  REVENUE_STREAMS = 'REVENUE_STREAMS'
}

export enum ETokenCanvasSteps {
  PROBLEM = "PROBLEM",
  TOKEN_USE_CASE = "TOKEN_USE_CASE",
  TOKEN_VELOCITY = "TOKEN_VELOCITY",
  PARTICIPANT_INCENTIVE = "PARTICIPANT_INCENTIVE"
}

export enum EFormType {
  GENERAL_INFO_FORM,
  CANVAS_FORM
}

export type StepName = EGeneralInfoSteps | ECanvasSteps;

export type Step = {
  name: StepName,
  isFinished?: boolean,
  stepNumber: number,
  numberOfSteps: number
}

export type WizardStep = {
  label: string,
  beginLabel?: string,
  endLabel?: string,
  steps: Step[]
}

export type StepTip = {
  tipTitle: string,
  tipSubtitle?: string,
  tips: string[],
  additionalLink?: {
    link: string,
    label: string
  }
}
