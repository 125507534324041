import {createSelector} from 'reselect';
import {RootState} from '../reduxTypes';

//Returns current active step
export const getActiveStep = createSelector(
  (state: RootState) => state.wizardSteps.wizardSteps,
  (state: RootState) => state.wizardSteps.activeStepName,
  (wizardSteps, activeStepName) => {

    for(let i=0; i<wizardSteps.length; i++) {
      const steps = wizardSteps[i].steps;

      for(let j=0; j<steps.length; j++) {
        if(steps[j].name === activeStepName)
          return steps[j];
      }
    }

    //If no step found return first one
    return wizardSteps[0].steps[0];
  }
);
