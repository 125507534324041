import React from 'react';
import {IAccentedComponent} from '../../../Common/Types/Common.types';
import './styles.scss';

interface IProgressBarProps extends IAccentedComponent{
  percent: number
}

const ProgressBar: React.FC<IProgressBarProps> = (props) => {

  const {
    percent,
    accentColor
  } = props;

  const progressPercent = Math.max(Math.min(percent, 100), 0);

  return(
    <div className='progressBar'>
        <div
          className={`progress ${accentColor}`}
          style={{width: `${progressPercent}%`}}
        />
    </div>
  )
};

export default ProgressBar;
