import {Action} from 'redux';
import {GeneralInfoInputs} from '../../Models/GeneralInfo.model';
import {CanvasModelInputs} from '../../Models/Canvas.model';

//Reducer state
export type WizardState = {
  generalInfoForm: GeneralInfoInputs | undefined,
  canvasForm: CanvasModelInputs | undefined,
  dbmcPdfBlob: Blob | undefined,
  tcPdfBlob: Blob | undefined
};

export const SET_GENERAL_INFO_FORM = "SET_GENERAL_INFO_FORM";
export interface SetGeneralInfoForm extends Action<typeof SET_GENERAL_INFO_FORM> {
  payload: {
    generalInfoForm: GeneralInfoInputs
  }
}

export const SET_CANVAS_FORM = "SET_CANVAS_FORM";
export interface SetCanvasForm extends Action<typeof SET_CANVAS_FORM> {
  payload: {
    canvasForm: CanvasModelInputs
  }
}

export const CLEAR_WIZARD_STATE = "CLEAR_WIZARD_STATE";
export interface ClearWizardState extends Action<typeof CLEAR_WIZARD_STATE>{
}

export const GENERATE_DBMC_PDF_REQUEST = "GENERATE_DBMC_PDF_REQUEST";
export const GENERATE_DBMC_PDF_ERROR = "GENERATE_DBMC_PDF_ERROR";
export const GENERATE_DBMC_PDF_SUCCESS = "GENERATE_DBMC_PDF_SUCCESS";
export interface GenerateDbmcPdfSuccess extends Action<typeof GENERATE_DBMC_PDF_SUCCESS> {
  payload: {
    dbmcPdfBlob: Blob
  }
}

export const GENERATE_TC_PDF_REQUEST = "GENERATE_TC_PDF_REQUEST";
export const GENERATE_TC_PDF_ERROR = "GENERATE_TC_PDF_ERROR";
export const GENERATE_TC_PDF_SUCCESS = "GENERATE_TC_PDF_SUCCESS";
export interface GenerateTcPdfSuccess extends Action<typeof GENERATE_TC_PDF_SUCCESS> {
  payload: {
    tcPdfBlob: Blob
  }
}

export const SEND_DBMC_PDF_REQUEST = "SEND_DBMC_PDF_REQUEST";
export const SEND_DBMC_PDF_ERROR = "SEND_DBMC_PDF_ERROR";
export const SEND_DBMC_PDF_SUCCESS = "SEND_DBMC_PDF_SUCCESS";

export type WizardActionTypes =
  SetGeneralInfoForm |
  SetCanvasForm |
  ClearWizardState |
  GenerateDbmcPdfSuccess |
  GenerateTcPdfSuccess
