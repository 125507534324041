import React from 'react';
import {useHistory} from 'react-router-dom'
import Page from '../../Elements/Page';
import Button from "../../Elements/Button";
import {ACCENT_COLOR} from "../../../Common/Types/Common.types";
import WizardHeader from "../../Components/WizardHeader";
import {ReactComponent as DBMCLogo} from '../../../Common/Assets/dbmc-logo-black.svg';
import './styles.scss';
import {Routes} from "../index";

const HomePage:React.FC = () => {

  const history = useHistory();

  return(
    <Page className='homePage p-0' fullPage={true}>
      <WizardHeader />
      <div className='homePageContainer'>
        <div className='dbmcLogo'>
          <DBMCLogo className='fluidSvg'/>
        </div>
        <h1 className='heroHeadline'>Decentralized Business Model Canvas</h1>
        <span className='descriptionText'>The only framework for evaluating decentralized business models you will ever need</span>
        <Button
          label='Let the Magic begin!'
          onClick={() => history.push(Routes.CANVAS_WIZARD)}
          accentColor={ACCENT_COLOR.PURPLE}
          centerContent={true}
          className='startWizardBtn'
        />
      </div>
    </Page>
  );
};

export default HomePage;
