export function isEmailValid(email: string) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
}

export function hasValue(value: any): boolean {
  if (typeof value === 'number' && !isNaN(value)) {
    return true;
  }

  if (Array.isArray(value)) {
    if (value.length > 0) {
      if (value.length > 1) {
        return value.every((val, index) => !!val || index === value.length-1)
      } else {
        return value.every(val => !!val)
      }
    } else {
      return false
    }
  }

  if (typeof value === 'object' && value !== null) {
    return isObjectFalsy(value);
  }

  return !!value;
}

export function isObjectFalsy(obj: any) {
  let isValid = true;

  // eslint-disable-next-line array-callback-return
  Object.keys(obj).map(key => {
    if (!hasValue(obj[key])) {
      isValid = false;
    }
  });

  return isValid;
}
