import React from 'react';
import { Provider } from 'react-redux';
import store from './Core/Redux/store';
import Pages from "./UI/Pages";
import {HashRouter} from "react-router-dom";
import Toastr from 'react-redux-toastr';

const App: React.FC = () => {
  return (
      <Provider store={store}>
        <div className='app'>
          <HashRouter>
            <div className='pages'>
              <Pages/>
            </div>
          </HashRouter>
        </div>

        <Toastr
          timeOut={5000}
          newestOnTop
          preventDuplicates
          position='top-right'
          transitionIn='fadeIn'
          transitionOut='fadeOut'
          closeOnToastrClick
          className='cusotmToastr'
        />
      </Provider>
  );
};

export default App;
