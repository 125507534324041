import React from 'react';
import Page from '../../Elements/Page';
import "./styles.scss";

const NotFoundPage: React.FC<any> = () => {
  return(
    <Page className='notFoundPage'>
      <p className="notFoundError fs-16">404 Not Found</p>
    </Page>
  );
};

export default NotFoundPage;
