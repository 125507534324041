/**
 * This reducer is used for controlling loading state of the specific action or bach of actions
 */
import { AppState } from '../store'
import { AnyAction, Reducer } from 'redux'

export interface LoadingState {
  [key: string]: boolean
}

const initialState: LoadingState = {};

export const loadingReducer: Reducer<LoadingState, AnyAction> = (state = initialState, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type);
  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) {
    return {
      ...state
    }
  }

  const requestName = matches[1];
  const requestState = matches[2];

  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving SOME_ACTION_REQUEST
    //      and false when receiving SOME_ACTION_SUCCESS / SOME_ACTION_FAILURE
    [requestName]: requestState === 'REQUEST'
  }
};

export const createLoadingSelector = (actions: string[]) => (state: AppState) => {
  return actions.some((action: any) => {
    const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(action);
    if (!matches) {
      return false
    }

    const requestName = matches[1];

    return state.loading[requestName]
  })
};

export default loadingReducer;
