// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react';

export function useXlScreenListener() {
  const [isXlScreen, setIsXlScreen] = useState();

  useEffect(() => {

    const handleScreenResize = () => {
      const matches = window.matchMedia("only screen and (min-width: 1200px)").matches;
      setIsXlScreen(matches);
    };

    handleScreenResize();
    window.addEventListener('resize', handleScreenResize);

    return () => {
      // @ts-ignore
      window.removeEventListener('resize', handleScreenResize);
    }
  }, []);

  return isXlScreen;
}
