import {
  REACT_APP_EMAILJS_USER_ID,
  REACT_APP_EMAILJS_SERVICE_ID,
  REACT_APP_EMAILJS_DBMC_TEMPLATE_ID
} from '../../Common/envConfig';
import {EmailJSAPI} from '../Utils/api.util';

class EmailService {

  private readonly serviceId: string;
  private readonly userId: string;
  private readonly dbmcTemplateId: string;

  constructor() {
    this.serviceId = REACT_APP_EMAILJS_SERVICE_ID as string;
    this.userId = REACT_APP_EMAILJS_USER_ID as string;
    this.dbmcTemplateId = REACT_APP_EMAILJS_DBMC_TEMPLATE_ID as string;
  }

  sendDBMCTo = (email: string, base64DbmcPdf: string, recaptchaToken: string, base64TcPdf?: string) => {
    const template_params = {
      'g-recaptcha-response': recaptchaToken,
      to_email: email,
      base64DbmcPdf: base64DbmcPdf
    };

    if(base64TcPdf) {
      //@ts-ignore
      template_params["base64TcPdf"] = base64TcPdf;
    }

    const data = {
      user_id: this.userId,
      service_id: this.serviceId,
      template_id: this.dbmcTemplateId,
      template_params
    };

    return EmailJSAPI.post('email/send', data, {
      headers: {'Content-type': 'application/json'}
    }).then(response => response.data);
  };
}

export default new EmailService();
