import React from 'react'
import {Step, WizardStep} from "../../../Common/Types/Steps.types";
import {allSteps} from "../../../Common/Constants/Steps.constants";
import {ICustomClassComponent} from "../../../Common/Types/Common.types";
import './styles.scss';

interface IStepStatusProps extends ICustomClassComponent {
  wizardSteps: WizardStep[],
  activeStep: Step
}

export const StepStatus: React.FC<IStepStatusProps> = (props) => {

  const {
    wizardSteps,
    activeStep,
    className
  } = props;

  const getStepNumber = (wizardStep: WizardStep) => {
    const currentlySelectedForm = isCurrentlySelectedForm(wizardStep);
    const indexOfActiveStep = allSteps.indexOf(activeStep.name);
    const indexOfRenderingStep = allSteps.indexOf(wizardStep.steps[0].name);

    /*
    If active step is below the form step to be rendered,
    meaning the ui still hadn't come to that point render, 0 eg 0/5
     */
    if(indexOfActiveStep < indexOfRenderingStep)
      return 0;

    /*
    If active step is in the form step to be rendered,
    simply return it's index, it's step number eg, 3/5
     */
    if(currentlySelectedForm)
      return activeStep.stepNumber;

    //Otherwise it means that form step is done, so we simply render it's length eg, 5/5
    return wizardStep.steps.length;
  };

  const isCurrentlySelectedForm = (wizardStep: WizardStep) => {
    return wizardStep.steps.some((step) => step.name === activeStep.name);
  };

  return(
    <div className={`stepStatusContainer ${className}`}>
      {wizardSteps.map(wizardStep => (
        <div className={`stepStatusLabel ${isCurrentlySelectedForm(wizardStep) ? 'selected' : ''}`} key={wizardStep.label}>
          {wizardStep.label} ({getStepNumber(wizardStep)}/{wizardStep.steps.length})
        </div>
      ))}
    </div>
  )
};
