import {
  CLEAR_WIZARD_STATE,
  ClearWizardState,
  GENERATE_DBMC_PDF_ERROR,
  GENERATE_DBMC_PDF_REQUEST,
  GENERATE_DBMC_PDF_SUCCESS,
  GenerateDbmcPdfSuccess,
  GENERATE_TC_PDF_ERROR,
  GENERATE_TC_PDF_REQUEST,
  GENERATE_TC_PDF_SUCCESS,
  GenerateTcPdfSuccess,
  SEND_DBMC_PDF_ERROR,
  SEND_DBMC_PDF_REQUEST,
  SEND_DBMC_PDF_SUCCESS,
  SET_CANVAS_FORM,
  SET_GENERAL_INFO_FORM,
  SetCanvasForm,
  SetGeneralInfoForm,
} from './wizard.types';
import {GeneralInfoInputs} from '../../Models/GeneralInfo.model';
import {CanvasModelInputs} from '../../Models/Canvas.model';
import {AppThunk} from "../reduxTypes";
import {pdf} from "@react-pdf/renderer";
import DBMCPdfDocument from "../../../PDF/Templates/Canvases/DBMC";
import TCPdfDocument from "../../../PDF/Templates/Canvases/TC";
import EmailService from "../../Services/email.service";
import {blobToBase64} from "../../Utils/common.util";
import {toastr} from "react-redux-toastr";

export function setGeneralInfoForm(generalInfoForm: GeneralInfoInputs): SetGeneralInfoForm {
  return {
    type: SET_GENERAL_INFO_FORM,
    payload: {
      generalInfoForm
    }
  }
}

export function setCanvasForm(canvasForm: CanvasModelInputs): SetCanvasForm {
  return {
    type: SET_CANVAS_FORM,
    payload: {
      canvasForm
    }
  }
}

export function clearWizardState(): ClearWizardState {
  return {
    type: CLEAR_WIZARD_STATE
  }
}

export function generateDbmcPdfSuccess(dbmcPdfBlob: Blob): GenerateDbmcPdfSuccess {
  return {
    type: GENERATE_DBMC_PDF_SUCCESS,
    payload: {
      dbmcPdfBlob
    }
  }
}

export function generateTcPdfSuccess(tcPdfBlob: Blob): GenerateTcPdfSuccess {
  return {
    type: GENERATE_TC_PDF_SUCCESS,
    payload: {
      tcPdfBlob
    }
  }
}

export function generateDbmcPdf(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: GENERATE_DBMC_PDF_REQUEST});
      const dbmcData = getState().wizard.canvasForm;
      const dbmcPdfBlob = await pdf(DBMCPdfDocument({...dbmcData})).toBlob();
      dispatch(generateDbmcPdfSuccess(dbmcPdfBlob));
    } catch(error) {
      dispatch({type: GENERATE_DBMC_PDF_ERROR});
    }
  }
}

export function generateTcPdf(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: GENERATE_TC_PDF_REQUEST});
      const dbmcData = getState().wizard.canvasForm;
      const tcPdfBlob = await pdf(TCPdfDocument({...dbmcData})).toBlob();
      dispatch(generateTcPdfSuccess(tcPdfBlob));
    } catch {
      dispatch({type: GENERATE_TC_PDF_ERROR});
    }
  }
}

export function sendDbmcPdf(email: string, recaptchaToken: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SEND_DBMC_PDF_REQUEST});
      const dbmcPdfBlob = getState().wizard.dbmcPdfBlob;
      const tcPdfBlob = getState().wizard.tcPdfBlob;
      const tcPdfBase64 = tcPdfBlob ? await blobToBase64(tcPdfBlob) : undefined;

      if(dbmcPdfBlob) {
        const dbmcPdfBase64 = await blobToBase64(dbmcPdfBlob);

        await EmailService.sendDBMCTo(email,dbmcPdfBase64,recaptchaToken, tcPdfBase64);
        dispatch({type: SEND_DBMC_PDF_SUCCESS});
        toastr.success("Check your inbox!", "We have sent you your very own DBMC.")
      } else {
        dispatch({type: SEND_DBMC_PDF_ERROR});
        toastr.error("Sorry", "An error occurred while sending the email");
      }
    } catch(error) {
      dispatch({type: SEND_DBMC_PDF_ERROR});
      toastr.error("Sorry", "An error occurred while sending the email");
    }
  }
}
