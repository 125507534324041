import React, {useEffect, useState} from 'react';
import {Col, Row} from 'reactstrap';
import Page from '../../Elements/Page';
import WizardSidebar from '../../Components/WizardSidebar';
import { useSelector} from 'react-redux'
import {getActiveStep} from '../../../Core/Redux/WizardSteps/wizardSteps.selectors';
import {AppState} from '../../../Core/Redux/store';
import {ACCENT_COLOR} from "../../../Common/Types/Common.types";
import {EFormType, Step} from "../../../Common/Types/Steps.types";
import {getFormType} from '../../../Core/Utils/common.util';
import GeneralInfoForm from "../../Components/GeneralInfoForm";
import CanvasForm from "../../Components/CanvasForm";
import WizardHeader from '../../Components/WizardHeader';
import {useXlScreenListener} from '../../../Core/Utils/hooks.util';
import './styles.scss';

const CanvasWizardPage: React.FC = () => {

  const activeStep: Step = useSelector((state: AppState) => getActiveStep(state));
  const [accentColor, setAccentColor] = useState<ACCENT_COLOR>(ACCENT_COLOR.PURPLE);
  const [formType, setFormType] = useState();
  const isXlScreen = useXlScreenListener();

  useEffect(() => {
    const form = getFormType(activeStep.name);

    if(form === EFormType.GENERAL_INFO_FORM) {
      setAccentColor(ACCENT_COLOR.PURPLE);
    } else if(form === EFormType.CANVAS_FORM) {
      setAccentColor(ACCENT_COLOR.CYAN);
    }

    setFormType(form)

  }, [activeStep.name]);

  return(
    <Page fullPage={true} className='canvasWizardPage p-0'>
      <Row noGutters className='h-100'>
        {
          isXlScreen && // If we are on desktop we show the sidebar here
          <Col className="flex-grow-0">
            <WizardSidebar
              accentColor={accentColor}
            />
          </Col>
        }
        <Col className='h-100 d-flex flex-column'>
          <WizardHeader/>
          {formType === EFormType.GENERAL_INFO_FORM &&
            <GeneralInfoForm
              formStep={activeStep}
              accentColor={accentColor}
            />
          }
          {formType === EFormType.CANVAS_FORM &&
            <CanvasForm
              formStep={activeStep}
              accentColor={accentColor}
            />
          }
        </Col>
      </Row>
    </Page>
  )
};

export default CanvasWizardPage;
