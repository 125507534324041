import React from 'react';
import {Document, Image, Link, Page, Text, View} from '@react-pdf/renderer';
import {CanvasModelInputs} from '../../../../Core/Models/Canvas.model';
import {ECanvasSteps, ETokenCanvasSteps} from '../../../../Common/Types/Steps.types';
import styles from '../styles';

const mvpLogo = require('../../../../Common/Assets/mvp-logo-wtext.png');
const icons = {
  [ETokenCanvasSteps.PROBLEM]: require('../../../../Common/Assets/CanvasIcons/problem.png'),
  [ECanvasSteps.PROPOSED_SOLUTION]: require('../../../../Common/Assets/CanvasIcons/proposed-solution.png'),
  [ECanvasSteps.INTERACTION_CHANNELS]: require('../../../../Common/Assets/CanvasIcons/interaction-channels.png'),
  [ECanvasSteps.TARGET_CUSTOMERS]: require('../../../../Common/Assets/CanvasIcons/customer-segments.png'),
  [ECanvasSteps.COST_STRUCTURE]: require('../../../../Common/Assets/CanvasIcons/cost-structure.png'),
  [ECanvasSteps.REVENUE_STREAMS]: require('../../../../Common/Assets/CanvasIcons/revenue-streams.png'),
  [ETokenCanvasSteps.PARTICIPANT_INCENTIVE]: require('../../../../Common/Assets/CanvasIcons/participant-incentive.png'),
  [ETokenCanvasSteps.TOKEN_USE_CASE]: require('../../../../Common/Assets/CanvasIcons/token-use-case.png'),
  [ETokenCanvasSteps.TOKEN_VELOCITY]: require('../../../../Common/Assets/CanvasIcons/token-velocity.png')
};

const DBMCPdfDocument = (canvasData: Partial<CanvasModelInputs>) => {

  const {
    proposedSolution,
    interactionChannels,
    targetUsers,
    costStructure,
    revenueStreams
  } = canvasData;

  const renderTCTable = () => (
    <View style={styles.tableContainer}>
      <View style={styles.tableRow}>
        <View style={styles.tableColumn}>
          {/*Problem*/}
          <View style={styles.stepHeaderContainer}>
            <View style={styles.stepNumberContainer}>
              <Text>01</Text>
            </View>
            <Text style={styles.tokenStepTextTitle}>Problem/Need</Text>
            <Image source={icons[ETokenCanvasSteps.PROBLEM]} style={styles.stepImg}/>
          </View>

        </View>
        <View style={styles.tableColumn}>

          {/* Proposed Solution */}
          <View style={{flexGrow: 1, borderBottomWidth: 1, borderColor: 'black', alignSelf: 'stretch'}}>
            <View style={styles.stepHeaderContainer}>
              <View style={styles.stepNumberContainer}>
                <Text>02</Text>
              </View>
              <Text style={styles.tokenStepTextTitle}>Proposed Solution</Text>
              <Image source={icons[ECanvasSteps.PROPOSED_SOLUTION]} style={styles.stepImg}/>
            </View>
            {proposedSolution && <Text style={styles.stepText}>{proposedSolution.value}</Text>}

          </View>

          {/*Token Velocity*/}
          <View style={{flexGrow: 1}}>
            <View style={styles.stepHeaderContainer}>
              <View style={styles.stepNumberContainer}>
                <Text>04</Text>
              </View>
              <Text style={styles.tokenStepTextTitle}>Desired Token Velocity</Text>
              <Image source={icons[ETokenCanvasSteps.TOKEN_VELOCITY]} style={styles.stepImg}/>
            </View>

          </View>

        </View>
        <View style={styles.tableColumn}>

          {/*Token Use Case*/}
          <View style={styles.stepHeaderContainer}>
            <View style={styles.stepNumberContainer}>
              <Text>03</Text>
            </View>
            <Text style={styles.tokenStepTextTitle}>Token Use-Case</Text>
            <Image source={icons[ETokenCanvasSteps.TOKEN_USE_CASE]} style={styles.stepImg}/>
          </View>

        </View>
        <View style={styles.tableColumn}>

          {/*Token Incentive*/}
          <View style={{flexGrow: 1, borderBottomWidth: 1, borderColor: 'black', alignSelf: 'stretch'}}>
            <View style={styles.stepHeaderContainer}>
              <View style={styles.stepNumberContainer}>
                <Text>06</Text>
              </View>
              <Text style={styles.tokenStepTextTitle}>Participant Incentivisation</Text>
              <Image source={icons[ETokenCanvasSteps.PARTICIPANT_INCENTIVE]} style={styles.stepImg}/>
            </View>

          </View>

          {/*Interaction Channels*/}
          <View style={{flexGrow: 1}}>
            <View style={styles.stepHeaderContainer}>
              <View style={styles.stepNumberContainer}>
                <Text>07</Text>
              </View>
              <Text style={styles.tokenStepTextTitle}>Interaction Channels</Text>
              <Image source={icons[ECanvasSteps.INTERACTION_CHANNELS]} style={styles.stepImg}/>
            </View>
            {
              interactionChannels && interactionChannels.value.map(interactionChannel =>
                <Text style={styles.stepText}>{`${interactionChannel} \n`}</Text>)
            }

          </View>

        </View>
        <View style={styles.tableColumn}>

          {/*User / Customer Segments*/}
          <View style={styles.stepHeaderContainer}>
            <View style={styles.stepNumberContainer}>
              <Text>05</Text>
            </View>
            <Text style={[styles.tokenStepTextTitle, {maxWidth: 130}]}>User/Customer Segments</Text>
            <Image source={icons[ECanvasSteps.TARGET_CUSTOMERS]} style={styles.stepImg}/>
          </View>
          {
            targetUsers && targetUsers.value.map(targetUser =>
              <Text style={styles.stepText}>{`${targetUser} \n`}</Text>)
          }
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableColumn}>
          {/*Revenue Streams*/}
          <View style={styles.stepHeaderContainer}>
            <View style={styles.stepNumberContainer}>
              <Text>08</Text>
            </View>
            <Text style={styles.tokenStepTextTitle}>Revenue Streams/Token Value Growth</Text>
            <Image source={icons[ECanvasSteps.REVENUE_STREAMS]} style={styles.stepImg}/>
          </View>
          {
            revenueStreams && revenueStreams.value.map(revenueStream =>
              <Text style={styles.stepText}>{`${revenueStream} \n`}</Text>)
          }

        </View>

        <View style={styles.tableColumn}>
          {/*Cost Structure*/}
          <View style={styles.stepHeaderContainer}>
            <View style={styles.stepNumberContainer}>
              <Text>09</Text>
            </View>
            <Text style={styles.tokenStepTextTitle}>Cost Structure/Necessary Resources</Text>
            <Image source={icons[ECanvasSteps.COST_STRUCTURE]} style={styles.stepImg}/>
          </View>
          {
            costStructure && costStructure.value.map(structure =>
              <Text style={styles.stepText}>{`${structure} \n`}</Text>)
          }

        </View>
      </View>
    </View>
  );

  return(
    <Document author='MVP Workshop' producer='MVP Workshop' creator='MVP Workshop'>
      <Page size={[700, 1150]} style={styles.page} orientation={'landscape'}>

        <View style={styles.pageContent}>
          <View style={styles.headerContainer}>
            <Text style={styles.pageTitle}>Decentralized Business Model Canvas</Text>
            <Image source={mvpLogo} style={styles.logoImg}/>
          </View>

          {renderTCTable()}

          <View style={styles.footerContainer}>
            <Link src='mailto:office@mvpworkshop.co'>office@mvpworkshop.co</Link>
            <Link src='https://mvpworkshop.co'>mvpworkhop.co</Link>
          </View>
        </View>
      </Page>
    </Document>
  )
};

export default DBMCPdfDocument;

