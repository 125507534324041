import React, {Fragment, useEffect, useState} from 'react';
import { IAccentedComponent} from "../../../Common/Types/Common.types";
import { ECanvasSteps, EFormType, Step } from '../../../Common/Types/Steps.types';
import {useDispatch, useSelector} from "react-redux";
import CanvasModel, {CanvasModelInputs} from '../../../Core/Models/Canvas.model';
import {nextStep, prevStep} from "../../../Core/Redux/WizardSteps/wizardSteps.actions";
import {setCanvasForm} from "../../../Core/Redux/Wizard/wizard.actions";
import { InputChange, InputsByStep } from '../../../Common/Types/Input.types';
import { getFormType, handleFormInputChange } from '../../../Core/Utils/common.util';
import ProgressBar from "../../Elements/ProgressBar";
import MultiTextInput from "../MultiTextInput";
import TextInput from '../TextInput';
import {AppState} from "../../../Core/Redux/store";
import WizardNavigation from "../../Elements/WizardNavigation";
import './styles.scss';
import WizardSidebar from "../WizardSidebar";
import {useXlScreenListener} from "../../../Core/Utils/hooks.util";
import { hasValue } from '../../../Core/Utils/validator.util';

interface ICanvasFormProps extends IAccentedComponent {
  formStep: Step
}

const inputByStep: InputsByStep<ECanvasSteps, CanvasModelInputs> = {
  [ECanvasSteps.TARGET_CUSTOMERS]: 'targetUsers',
  [ECanvasSteps.VALUE_PROPOSITION]: 'valueProposition',
  [ECanvasSteps.PROPOSED_SOLUTION]: 'proposedSolution',
  [ECanvasSteps.REACHING_TRUST]: 'trustMechanisms',
  [ECanvasSteps.VALIDATOR_INCENTIVE]: 'validatorIncentives',
  [ECanvasSteps.INTERACTION_CHANNELS]: 'interactionChannels',
  [ECanvasSteps.NETWORK_GOVERNANCE]: 'networkGovernance',
  [ECanvasSteps.COST_STRUCTURE]: 'costStructure',
  [ECanvasSteps.REVENUE_STREAMS]: 'revenueStreams'
};

const CanvasForm: React.FC<ICanvasFormProps> = (props) => {

  const dispatch = useDispatch();

  const [formState, setFormState] = useState<CanvasModelInputs>(new CanvasModel().getInputs);
  const reduxCanvasForm = useSelector((state: AppState) => state.wizard.canvasForm);
  const isXlScreen = useXlScreenListener();
  const [requiredError, setRequiredError] = useState(true);

  const {
    formStep,
    accentColor
  } = props;

  useEffect(() => {
    if(reduxCanvasForm) {
      setFormState(reduxCanvasForm)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrevious = () => {
    dispatch(prevStep());
  };

  useEffect(() => {
    if (getFormType(formStep.name) !== EFormType.CANVAS_FORM) {
      return;
    }

    const currentInputName = inputByStep[formStep.name as ECanvasSteps];

    if (hasValue(formState[currentInputName].value)) {
      setRequiredError(false);
    } else {
      setRequiredError(true);
    }
  }, [formState, formStep]);

  const handleNext = () => {
    if(formStep.stepNumber === formStep.numberOfSteps)
      dispatch(setCanvasForm(formState));
    dispatch(nextStep());
  };

  const handleInputChange = (callback: InputChange<any>) => {
    handleFormInputChange(setFormState, callback);
  };

  const renderSteps = () => {
    switch (formStep.name) {
      case ECanvasSteps.TARGET_CUSTOMERS:
        return(
          <MultiTextInput
            name={formState.targetUsers.name}
            value={formState.targetUsers.value}
            onChange={handleInputChange}
            label="Who Are Your Target Users/Customers?"
            placeholder="Target user"
            formStep={formStep}
            accentColor={accentColor}
          />
        );
      case ECanvasSteps.VALUE_PROPOSITION:
        return (
          <MultiTextInput
            name={formState.valueProposition.name}
            value={formState.valueProposition.value}
            onChange={handleInputChange}
            label="What Is The Value You Promise To Deliver?"
            placeholder="List each of the value streams you provide"
            formStep={formStep}
            accentColor={accentColor}
          />
        );
      case ECanvasSteps.PROPOSED_SOLUTION:
        return(
          <TextInput
            type="textarea"
            name={formState.proposedSolution.name}
            value={formState.proposedSolution.value}
            onChange={handleInputChange}
            label="How Does Your Solution Look like?"
            placeholder="Describe it thoroughly - fix the style later"
            formStep={formStep}
            accentColor={accentColor}
          />
        );
      case ECanvasSteps.REACHING_TRUST:
        return(
          <MultiTextInput
            name={formState.trustMechanisms.name}
            value={formState.trustMechanisms.value}
            onChange={handleInputChange}
            label="What Mechanisms You Plan To Use To Reach Trust?"
            placeholder="Thread carefully..."
            formStep={formStep}
            accentColor={accentColor}
          />
        );
      case ECanvasSteps.VALIDATOR_INCENTIVE:
        return(
          <MultiTextInput
            name={formState.validatorIncentives.name}
            value={formState.validatorIncentives.value}
            onChange={handleInputChange}
            label="What Are The Incentives For validators?"
            placeholder="What would drive you to be a good validator?"
            formStep={formStep}
            accentColor={accentColor}
          />
        );
      case ECanvasSteps.INTERACTION_CHANNELS:
        return(
          <MultiTextInput
            name={formState.interactionChannels.name}
            value={formState.interactionChannels.value}
            onChange={handleInputChange}
            label="How Are You Going to Communicate With The End-Users?"
            placeholder="Your most valuable asset are your users..."
            formStep={formStep}
            accentColor={accentColor}
          />
        );
      case ECanvasSteps.NETWORK_GOVERNANCE:
        return(
          <TextInput
            type="textarea"
            name={formState.networkGovernance.name}
            value={formState.networkGovernance.value}
            onChange={handleInputChange}
            label="How Are Decisions Made Inside The Network?"
            placeholder="Decisions, decisions..."
            formStep={formStep}
            accentColor={accentColor}
          />
        );
      case ECanvasSteps.COST_STRUCTURE:
        return(
          <MultiTextInput
            name={formState.costStructure.name}
            value={formState.costStructure.value}
            onChange={handleInputChange}
            label="What Are The Key Costs of Doing Business?"
            placeholder="You need to spend money to make money..."
            formStep={formStep}
            accentColor={accentColor}
          />
        );
      case ECanvasSteps.REVENUE_STREAMS:
        return(
          <MultiTextInput
            name={formState.revenueStreams.name}
            value={formState.revenueStreams.value}
            onChange={handleInputChange}
            label="What Are The Key Ways You Plan to Generate Revenue?"
            placeholder="Be creative, but stay realistic..."
            formStep={formStep}
            accentColor={accentColor}
          />
        )
    }
  };

  const progressPercent = formStep.stepNumber / formStep.numberOfSteps * 100;

  return(
    <Fragment>
      <div className='generalInfoForm'>
        <div className='formContainer'>

          <div className='inputContainer'>
            {renderSteps()}
            <ProgressBar percent={progressPercent} accentColor={accentColor}/>
          </div>

          {isXlScreen && <WizardNavigation
            formStep={formStep}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            accentColor={accentColor}
            nextDisabled={requiredError}
          />}
        </div>
      </div>
      {
        !isXlScreen && // If not desktop show the component here
        <Fragment>
          <WizardSidebar
            accentColor={accentColor}
          />
          <WizardNavigation
            formStep={formStep}
            handlePrevious={() => {}}
            handleNext={handleNext}
            accentColor={accentColor}
            nextDisabled={requiredError}
          />
        </Fragment>
      }
    </Fragment>
  )
};

export default CanvasForm;
