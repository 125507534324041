import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';

export type AppState = ReturnType<typeof rootReducer>;

const middlewareList = [thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewareList);

const store = createStore (
    rootReducer,
    composeWithDevTools(middlewareEnhancer)
);

export default store;
