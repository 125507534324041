import {StyleSheet} from "@react-pdf/renderer";
import {Font} from "@react-pdf/renderer";

Font.register({family: 'TitilliumWeb', fonts: [
  {src: require('../../../Common/Assets/Fonts/TitilliumWeb-Regular.ttf')},
  {src: require('../../../Common/Assets/Fonts/TitilliumWeb-Bold.ttf'), fontWeight: 700}
]});

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'TitilliumWeb'
  },
  pageContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 5,
    paddingHorizontal: 15
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    marginBottom: 30,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 12,
    color: 'gray',
    paddingVertical: 10
  },
  pageTitle: {
    fontSize: 30,
    fontWeight: 'bold'
  },
  tableContainer: {
    display: 'flex',
    flexGrow: 1,
    borderWidth: 6,
    borderColor: 'black',
    padding: 0
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  tableColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-start',
    width: 0,
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderColor: 'black',
    alignSelf: 'stretch'
  },
  stepHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  stepNumberContainer: {
    alignSelf: 'flex-start',
    backgroundColor: 'black',
    color: 'white',
    fontFamily: 'TitilliumWeb',
    fontWeight: 'bold',
    width: 35,
    height: 35,
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center'
  },
  stepTextTitle: {
    fontFamily: 'TitilliumWeb',
    fontWeight: 'bold',
    fontSize: 15,
    marginHorizontal: 10,
    maxWidth: 135
  },
  stepText: {
    padding: 5,
    fontSize: 11,
  },
  stepImg: {
    width: 30,
    height: 30
  },
  logoImg: {
    width: 'auto',
    height: 45
  },
  tokenStepTextTitle: {
    fontFamily: 'TitilliumWeb',
    fontWeight: 'bold',
    fontSize: 11,
    marginHorizontal: 10,
    maxWidth: 135
  }
});

export default styles;
