import {
  CLEAR_WIZARD_STEPS_STATE, ClearWizardStepsState,
  SET_ACTIVE_STEP, SetActiveStep,
  UPDATE_STEP_STATUS, UpdateStepStatus
} from './wizardSteps.types';
import {ECanvasSteps, EGeneralInfoSteps} from '../../../Common/Types/Steps.types';
import {AppThunk} from "../reduxTypes";
import {allSteps} from "../../../Common/Constants/Steps.constants";
import history from '../../Utils/history.util';
import {Routes} from "../../../UI/Pages";
import {TOKEN_SELECTION} from "../../../Common/Types/Canvas.types";

export function updateStepStatus(stepName: EGeneralInfoSteps | ECanvasSteps , isFinished: boolean): UpdateStepStatus {
  return {
    type: UPDATE_STEP_STATUS,
    payload: {
      stepName,
      isFinished
    }
  }
}

export function setActiveStep(stepName: EGeneralInfoSteps | ECanvasSteps): SetActiveStep {
  return {
    type: SET_ACTIVE_STEP,
    payload: {
      activeStepName: stepName
    }
  }
}

export function clearWizardStepsState(): ClearWizardStepsState {
  return {
    type: CLEAR_WIZARD_STEPS_STATE
  }
}

export function nextStep(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const currentStepName = getState().wizardSteps.activeStepName;
      const currentStepIndex = allSteps.indexOf(currentStepName);

      if(currentStepIndex === allSteps.length-1) {
        const generalInfoForm = getState().wizard.generalInfoForm;

        if(generalInfoForm && generalInfoForm.isThereToken.value.value === TOKEN_SELECTION.TOKEN_PLANNED) {
          history.push(Routes.WIZARD_BONUS_STEP);
        } else {
          history.push(Routes.WIZARD_DONE);
        }
      } else {
        dispatch(setActiveStep(allSteps[currentStepIndex+1]));
        dispatch(updateStepStatus(currentStepName, true));
      }
    } catch {

    }
  }
}

export function prevStep(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const currentStepName = getState().wizardSteps.activeStepName;
      const currentStepIndex = allSteps.indexOf(currentStepName);

      if(currentStepIndex === 0) {
        //Here handle exiting wizard
        history.push(Routes.HOME_PAGE);
      }else {
        dispatch(setActiveStep(allSteps[currentStepIndex-1]));
      }
    } catch {

    }
  }
}
