import React, {useState} from 'react';
import {IAccentedComponent} from "../../../Common/Types/Common.types";
import {stepTips} from '../../../Common/Constants/Steps.constants';
import {useSelector} from 'react-redux';
import {AppState} from "../../../Core/Redux/store";
import {getActiveStep} from "../../../Core/Redux/WizardSteps/wizardSteps.selectors";
import {ReactComponent as SidebarBtn} from '../../../Common/Assets/chevron-black.svg';
import {ReactComponent as DBMCLogo} from '../../../Common/Assets/dbmc-logo.svg';
import Slider from 'react-slick';
import Separator from "../../Elements/Separator";
import {StepStatus} from "../../Elements/StepStatus";
import './styles.scss';
import {useXlScreenListener} from "../../../Core/Utils/hooks.util";

interface IWizardSidebarProps extends IAccentedComponent {
}

const WizardSidebar: React.FC<IWizardSidebarProps> = (props) => {

  const activeStep = useSelector((state: AppState) => getActiveStep(state));
  const wizardSteps = useSelector((state: AppState) => state.wizardSteps.wizardSteps);
  const isXlScreen = useXlScreenListener();

  const [showStepHelp, setShowStepHelp] = useState(true);

  const {
    accentColor
  } = props;

  const renderTips = () => {
    const activeStepTips = stepTips[activeStep.name];

    return(
      <div>
        {activeStepTips.tipSubtitle && <h5 className='tipSubtitle'>{activeStepTips.tipSubtitle}</h5>}
        <h1 className='tipTitle'>{activeStepTips.tipTitle}</h1>
        <Slider
          infinite={true}
          arrows={false}
          autoplay={true}
          autoplaySpeed={5000}
          dots={true}
          appendDots={dots => <div>{dots}</div>}
          dotsClass='slick-dots'
          key={activeStep.name}
        >
          {activeStepTips.tips.map(tip => (
            <div className='tip' key={tip}>
              {tip}
            </div>
          ))}
        </Slider>
      </div>
    )
  };

  const additionalLink = stepTips[activeStep.name].additionalLink;
  const sideBarBtnOpenClass = `${isXlScreen ? 'rotate-270' : 'rotate-180'}`;
  const sideBarBtnClosedClass = `${isXlScreen ? 'rotate-90' : ''}`;

  return(
    <div className='wizardSidebar'>
      <div className='d-xl-block d-none'>
        <StepStatus wizardSteps={wizardSteps} activeStep={activeStep}/>
      </div>
      <div className={`stepHelp ${showStepHelp && 'open'}`}>
        {
          showStepHelp &&
          <div className='stepHelpContent'>
            <div className='d-xl-block d-none'>
              <div className='titleContainer'>
                <DBMCLogo/>
                <div className='sidebarLogoText'>Decentralised Business Model Canvas</div>
              </div>
            </div>

            {renderTips()}

            <div className='footerContainer'>
              {additionalLink &&
                <a
                  className={`additionalLink ${accentColor}`}
                  href={additionalLink.link}
                  target="_blank"
                  rel='noopener noreferrer'
                >
                  {additionalLink.label}
                </a>
              }
              <div className='d-xl-block d-none'>
                <Separator className='separatorClass'/>
                <span className='mvpWorkshopSignature'>
                  Powered by&nbsp;
                  <a
                    className={`additionalLink ${accentColor}`}
                    href='https://mvpworkshop.co'
                    target="_blank"
                    rel='noopener noreferrer'
                  >
                    MVP Workshop
                  </a>
                </span>
              </div>
            </div>
          </div>
        }
        <button
          className={`toggleSidebarBtn ${showStepHelp && 'open'}`}
          onClick={() => {setShowStepHelp(prevState => !prevState)}}
        >
          <div>
            <SidebarBtn className={`${showStepHelp ? sideBarBtnOpenClass : sideBarBtnClosedClass}`}/>
          </div>
        </button>
      </div>
    </div>
  )
};

export default WizardSidebar;
