import React from 'react';
import {Document, Image, Link, Page, Text, View} from '@react-pdf/renderer';
import {CanvasModelInputs} from '../../../../Core/Models/Canvas.model';
import {ECanvasSteps} from '../../../../Common/Types/Steps.types';
import styles from '../styles';

const mvpLogo = require('../../../../Common/Assets/mvp-logo-wtext.png');
const icons = {
  [ECanvasSteps.PROPOSED_SOLUTION]: require('../../../../Common/Assets/CanvasIcons/proposed-solution.png'),
  [ECanvasSteps.VALIDATOR_INCENTIVE]: require('../../../../Common/Assets/CanvasIcons/validator-incentive.png'),
  [ECanvasSteps.REACHING_TRUST]: require('../../../../Common/Assets/CanvasIcons/reaching-trust.png'),
  [ECanvasSteps.VALUE_PROPOSITION]: require('../../../../Common/Assets/CanvasIcons/value-proposition.png'),
  [ECanvasSteps.NETWORK_GOVERNANCE]: require('../../../../Common/Assets/CanvasIcons/network-governance.png'),
  [ECanvasSteps.INTERACTION_CHANNELS]: require('../../../../Common/Assets/CanvasIcons/interaction-channels.png'),
  [ECanvasSteps.TARGET_CUSTOMERS]: require('../../../../Common/Assets/CanvasIcons/customer-segments.png'),
  [ECanvasSteps.COST_STRUCTURE]: require('../../../../Common/Assets/CanvasIcons/cost-structure.png'),
  [ECanvasSteps.REVENUE_STREAMS]: require('../../../../Common/Assets/CanvasIcons/revenue-streams.png')
};

const DBMCPdfDocument = (canvasData: Partial<CanvasModelInputs>) => {

  const {
    proposedSolution,
    validatorIncentives,
    trustMechanisms,
    valueProposition,
    networkGovernance,
    interactionChannels,
    targetUsers,
    costStructure,
    revenueStreams
  } = canvasData;

  const renderDBMCTable = () => (
    <View style={styles.tableContainer}>
      <View style={styles.tableRow}>
        <View style={styles.tableColumn}>
          {/*Proposed Solution*/}
          <View style={styles.stepHeaderContainer}>
            <View style={styles.stepNumberContainer}>
              <Text>03</Text>
            </View>
            <Text style={styles.stepTextTitle}>Proposed Solution</Text>
            <Image source={icons[ECanvasSteps.PROPOSED_SOLUTION]} style={styles.stepImg}/>
          </View>
          {proposedSolution && <Text style={styles.stepText}>{proposedSolution.value}</Text>}

        </View>
        <View style={styles.tableColumn}>

          {/*Validator Incentive*/}
          <View style={{flexGrow: 1, borderBottomWidth: 1, borderColor: 'black', alignSelf: 'stretch'}}>
            <View style={styles.stepHeaderContainer}>
              <View style={styles.stepNumberContainer}>
                <Text>05</Text>
              </View>
              <Text style={styles.stepTextTitle}>Validator Incentive</Text>
              <Image source={icons[ECanvasSteps.VALIDATOR_INCENTIVE]} style={styles.stepImg}/>
            </View>
            {
              validatorIncentives && validatorIncentives.value.map(validatorIncentive =>
                <Text style={styles.stepText}>{`${validatorIncentive} \n`}</Text>)
            }

          </View>

          {/*Reaching Trust*/}
          <View style={{flexGrow: 1}}>
            <View style={styles.stepHeaderContainer}>
              <View style={styles.stepNumberContainer}>
                <Text>04</Text>
              </View>
              <Text style={styles.stepTextTitle}>Reaching Trust</Text>
              <Image source={icons[ECanvasSteps.REACHING_TRUST]} style={styles.stepImg}/>
            </View>
            {
              trustMechanisms && trustMechanisms.value.map(trustMechanism =>
                <Text style={styles.stepText}>{`${trustMechanism} \n`}</Text>)
            }

          </View>

        </View>
        <View style={styles.tableColumn}>

          {/*Value Proposition*/}
          <View style={styles.stepHeaderContainer}>
            <View style={styles.stepNumberContainer}>
              <Text>02</Text>
            </View>
            <Text style={styles.stepTextTitle}>Value Proposition</Text>
            <Image source={icons[ECanvasSteps.VALUE_PROPOSITION]} style={styles.stepImg}/>
          </View>
          {
            valueProposition && valueProposition.value.map(proposition =>
              <Text style={styles.stepText}>{`${proposition} \n`}</Text>)
          }

        </View>
        <View style={styles.tableColumn}>

          {/*Network Governance*/}
          <View style={{flexGrow: 1, borderBottomWidth: 1, borderColor: 'black', alignSelf: 'stretch'}}>
            <View style={styles.stepHeaderContainer}>
              <View style={styles.stepNumberContainer}>
                <Text>07</Text>
              </View>
              <Text style={styles.stepTextTitle}>Network Governance</Text>
              <Image source={icons[ECanvasSteps.NETWORK_GOVERNANCE]} style={styles.stepImg}/>
            </View>
            {networkGovernance && <Text style={styles.stepText}>{networkGovernance.value}</Text>}


          </View>

          {/*Interaction Channels*/}
          <View style={{flexGrow: 1}}>
            <View style={styles.stepHeaderContainer}>
              <View style={styles.stepNumberContainer}>
                <Text>08</Text>
              </View>
              <Text style={styles.stepTextTitle}>Interaction Channels</Text>
              <Image source={icons[ECanvasSteps.INTERACTION_CHANNELS]} style={styles.stepImg}/>
            </View>
            {
              interactionChannels && interactionChannels.value.map(interactionChannel =>
                <Text style={styles.stepText}>{`${interactionChannel} \n`}</Text>)
            }

          </View>

        </View>
        <View style={styles.tableColumn}>

          {/*User / Customer Segments*/}
          <View style={styles.stepHeaderContainer}>
            <View style={styles.stepNumberContainer}>
              <Text>01</Text>
            </View>
            <Text style={[styles.stepTextTitle, {maxWidth: 130}]}>User / Customer Segments</Text>
            <Image source={icons[ECanvasSteps.TARGET_CUSTOMERS]} style={styles.stepImg}/>
          </View>
          {
            targetUsers && targetUsers.value.map(targetUser =>
              <Text style={styles.stepText}>{`${targetUser} \n`}</Text>)
          }

        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableColumn}>

          {/*Cost Structure*/}
          <View style={styles.stepHeaderContainer}>
            <View style={styles.stepNumberContainer}>
              <Text>06</Text>
            </View>
            <Text style={styles.stepTextTitle}>Cost Structure</Text>
            <Image source={icons[ECanvasSteps.COST_STRUCTURE]} style={styles.stepImg}/>
          </View>
          {
            costStructure && costStructure.value.map(structure =>
              <Text style={styles.stepText}>{`${structure} \n`}</Text>)
          }

        </View>

        <View style={styles.tableColumn}>

          {/*Revenue Streams*/}
          <View style={styles.stepHeaderContainer}>
            <View style={styles.stepNumberContainer}>
              <Text>09</Text>
            </View>
            <Text style={styles.stepTextTitle}>Revenue Streams</Text>
            <Image source={icons[ECanvasSteps.REVENUE_STREAMS]} style={styles.stepImg}/>
          </View>
          {
            revenueStreams && revenueStreams.value.map(revenueStream =>
              <Text style={styles.stepText}>{`${revenueStream} \n`}</Text>)
          }

        </View>
      </View>
    </View>
  );

  return(
    <Document author='MVP Workshop' producer='MVP Workshop' creator='MVP Workshop'>
      <Page size={[700, 1150]} style={styles.page} orientation={'landscape'}>

        <View style={styles.pageContent}>
          <View style={styles.headerContainer}>
            <Text style={styles.pageTitle}>Decentralized Business Model Canvas</Text>
            <Image source={mvpLogo} style={styles.logoImg}/>
          </View>

          {renderDBMCTable()}

          <View style={styles.footerContainer}>
            <Link src='mailto:office@mvpworkshop.co'>office@mvpworkshop.co</Link>
            <Link src='https://mvpworkshop.co'>mvpworkhop.co</Link>
          </View>
        </View>
      </Page>
    </Document>
  )
};

export default DBMCPdfDocument;

