import {Action} from 'redux';
import {EGeneralInfoSteps, ECanvasSteps, WizardStep} from '../../../Common/Types/Steps.types';

//Reducer state
export type WizardStepsState = {
  activeStepName: EGeneralInfoSteps | ECanvasSteps,
  wizardSteps: WizardStep[]
};

export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export interface SetActiveStep extends Action<typeof SET_ACTIVE_STEP>{
  payload: {
    activeStepName: EGeneralInfoSteps | ECanvasSteps
  }
}

export const UPDATE_STEP_STATUS = "UPDATE_STEP_STATUS";
export interface UpdateStepStatus extends Action<typeof UPDATE_STEP_STATUS>{
  payload: {
    stepName: EGeneralInfoSteps | ECanvasSteps,
    isFinished: boolean
  }
}

export const CLEAR_WIZARD_STEPS_STATE = "CLEAR_WIZARD_STEPS_STATE";
export interface ClearWizardStepsState extends Action<typeof CLEAR_WIZARD_STEPS_STATE>{
}

export type WizardStepsActionTypes =
  SetActiveStep |
  UpdateStepStatus |
  ClearWizardStepsState
