import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import NotFoundPage from './NotFoundPage';
import HomePage from './HomePage';
import CanvasWizardPage from "./CanvasWizardPage";
import WizardBonusStepPage from './WizardBonusStepPage';
import WizardDonePage from './WizardDonePage';

export enum Routes {
  HOME_PAGE = '/',
  CANVAS_WIZARD = '/canvas-wizard',
  NOT_FOUND = '/not-found',
  WIZARD_BONUS_STEP = '/bonus-step',
  WIZARD_DONE = '/dbmc-done'
}

const Pages: React.FC<any> = () => (
  <Switch>
    <Route exact path={Routes.HOME_PAGE} component={HomePage}/>
    <Route exact path={Routes.CANVAS_WIZARD} component={CanvasWizardPage}/>
    <Route exact path={Routes.NOT_FOUND} component={NotFoundPage}/>
    <Route exact path={Routes.WIZARD_BONUS_STEP} component={WizardBonusStepPage}/>
    <Route exact path={Routes.WIZARD_DONE} component={WizardDonePage} />
    <Redirect to={'/not-found'}/>
  </Switch>
);

export default Pages
