import {TextInputValueType} from '../../UI/Components/TextInput';
import {MultiTextValueType} from '../../UI/Components/MultiTextInput';

import {InputDataModel, FormModelInputTypes, IFormModel} from '../../Common/Types/Model.types';

export type CanvasModelInputs = {
  targetUsers: InputDataModel<MultiTextValueType>,
  valueProposition: InputDataModel<MultiTextValueType>,
  proposedSolution: InputDataModel<TextInputValueType>,
  trustMechanisms: InputDataModel<MultiTextValueType>,
  validatorIncentives: InputDataModel<MultiTextValueType>,
  interactionChannels: InputDataModel<MultiTextValueType>,
  networkGovernance: InputDataModel<TextInputValueType>,
  costStructure: InputDataModel<MultiTextValueType>,
  revenueStreams: InputDataModel<MultiTextValueType>
}

class CanvasModel implements  IFormModel<CanvasModelInputs> {

  private _targetUsers: InputDataModel<MultiTextValueType> = {
    name: 'targetUsers',
    value: ['']
  };

  get targetUsers(): InputDataModel<MultiTextValueType> {
    return this._targetUsers;
  };

  private _valueProposition: InputDataModel<MultiTextValueType> = {
    name: 'valueProposition',
    value: ['']
  };

  get valueProposition(): InputDataModel<MultiTextValueType> {
    return this._valueProposition;
  }

  private _proposedSolution: InputDataModel<TextInputValueType> = {
    name: 'proposedSolution',
    value: ''
  };

  get proposedSolution(): InputDataModel<TextInputValueType> {
    return this._proposedSolution;
  };

  private _trustMechanisms: InputDataModel<MultiTextValueType> = {
    name: 'trustMechanisms',
    value: ['']
  };

  get trustMechanisms(): InputDataModel<MultiTextValueType> {
    return this._trustMechanisms;
  };

  private _validatorIncentives: InputDataModel<MultiTextValueType> = {
    name: 'validatorIncentives',
    value: ['']
  };

  get validatorIncentives(): InputDataModel<MultiTextValueType> {
    return this._validatorIncentives;
  };

  private _interactionChannels: InputDataModel<MultiTextValueType> = {
    name: 'interactionChannels',
    value: ['']
  };

  get interactionChannels(): InputDataModel<MultiTextValueType> {
    return this._interactionChannels;
  };

  private _networkGovernance: InputDataModel<TextInputValueType> = {
    name: 'networkGovernance',
    value: ''
  };

  get networkGovernance(): InputDataModel<TextInputValueType> {
    return this._networkGovernance;
  };

  private _costStructure: InputDataModel<MultiTextValueType> = {
    name: 'costStructure',
    value: ['']
  };

  get costStructure(): InputDataModel<MultiTextValueType> {
    return this._costStructure;
  };

  private _revenueStreams: InputDataModel<MultiTextValueType> = {
    name: 'revenueStreams',
    value: ['']
  };

  get revenueStreams(): InputDataModel<MultiTextValueType> {
    return this._revenueStreams;
  };

  public getInputs = () => {
    return {
      targetUsers: this._targetUsers,
      valueProposition: this._valueProposition,
      proposedSolution: this._proposedSolution,
      trustMechanisms: this._trustMechanisms,
      validatorIncentives: this._validatorIncentives,
      interactionChannels: this._interactionChannels,
      networkGovernance: this._networkGovernance,
      costStructure: this._costStructure,
      revenueStreams: this._revenueStreams
    }
  };

  public setInputsValues = (values: FormModelInputTypes<CanvasModelInputs>) => {
    this._targetUsers.value = values.targetUsers;
    this._valueProposition.value = values.valueProposition;
    this._proposedSolution.value = values.proposedSolution;
    this._trustMechanisms.value = values.trustMechanisms;
    this._validatorIncentives.value = values.validatorIncentives;
    this._interactionChannels.value = values.interactionChannels;
    this._networkGovernance.value = values.networkGovernance;
    this._costStructure.value = values.costStructure;
    this._revenueStreams.value = values.revenueStreams;
  };
}

export default CanvasModel;
