import {TextInputValueType} from '../../UI/Components/TextInput';
import {IDropdownOption} from '../../UI/Components/DropdownSelection';
import {IButtonSelectionOption} from '../../UI/Components/ButtonSelection';

import {InputDataModel, FormModelInputTypes, IFormModel} from '../../Common/Types/Model.types';

export type GeneralInfoInputs = {
  projectName: InputDataModel<TextInputValueType>,
  industry: InputDataModel<IDropdownOption>,
  description: InputDataModel<TextInputValueType>,
  businessModel: InputDataModel<TextInputValueType>,
  isThereToken: InputDataModel<IButtonSelectionOption>
}

class GeneralInfoModel implements IFormModel<GeneralInfoInputs> {

  private _projectName: InputDataModel<TextInputValueType> = {
    name: 'projectName',
    value: ''
  };

  get projectName(): InputDataModel<TextInputValueType> {
    return this._projectName;
  }

  private _industry: InputDataModel<IDropdownOption> = {
    name: 'industry',
    value: {
      label: '',
      value: ''
    }
  };

  get industry(): InputDataModel<IDropdownOption> {
    return this._industry;
  }

  private _description: InputDataModel<TextInputValueType> = {
    name: 'description',
    value: ''
  };

  get description(): InputDataModel<TextInputValueType> {
    return this._description;
  }

  private _businessModel: InputDataModel<TextInputValueType> = {
    name: 'businessModel',
    value: ''
  };

  get businessModel(): InputDataModel<TextInputValueType> {
    return this._businessModel;
  }

  private _isThereToken: InputDataModel<IButtonSelectionOption> = {
    name: 'isThereToken',
    value: {
      value: '',
      label: ''
    }
  };

  get isThereToken(): InputDataModel<IButtonSelectionOption> {
    return this._isThereToken;
  }

  public getInputs = () => {
    return {
      projectName: this._projectName,
      industry: this._industry,
      description: this._description,
      businessModel: this._businessModel,
      isThereToken: this._isThereToken
    }
  };

  public setInputsValues = (values: FormModelInputTypes<GeneralInfoInputs>) => {
    this._projectName.value = values.projectName;
    this._industry.value = values.industry;
    this._description.value = values.description;
    this._businessModel.value = values.businessModel;
    this._isThereToken.value = values.isThereToken;
  };
}

export default GeneralInfoModel;
