export enum TOKEN_SELECTION {
  TOKEN_PLANNED = "TOKEN_PLANNED",
  NO_TOKEN_PLANNED = "NO_TOKEN_PLANNED"
}

export enum INDUSTRY_LIST {
  BLOCKCHAIN_AND_CRYPTO = "BLOCKCHAIN_AND_CRYPTO",
  BANKING_AND_FINANCIAL = "BANKING_AND_FINANCIAL",
  REAL_ESTATE = "REAL_ESTATE",
  SUPPLY_CHAIN_LOGISTICS = "SUPPLY_CHAIN_LOGISTICS",
  INTELLECTUAL_PROPERTY = "INTELLECTUAL_PROPERTY",
  MEDICAL_AND_HEALTHCARE = "MEDICAL_AND_HEALTHCARE",
  EDUCATION = "EDUCATION",
  PRIVACY_AND_SECURITY = "PRIVACY_AND_SECURITY",
  GAMING_AND_ESPORTS = "GAMING_AND_ESPORTS",
  MEDIA_AND_ENTERTAINMENT = "MEDIA_AND_ENTERTAINMENT",
  TRAVEL_AND_TOURISM = "TRAVEL_AND_TOURISM",
  OTHER = "OTHER"
}
