import React from 'react'
import Button from "../Button";
import {ReactComponent as ChevronGray} from "../../../Common/Assets/chevron-gray.svg";
import {ACCENT_COLOR, IAccentedComponent} from "../../../Common/Types/Common.types";
import {ReactComponent as ChevronWhite} from "../../../Common/Assets/chevron-white.svg";
import {allSteps, stepLabels} from "../../../Common/Constants/Steps.constants";
import {ECanvasSteps, EGeneralInfoSteps, Step} from "../../../Common/Types/Steps.types";
import {padNumber} from "../../../Core/Utils/common.util";
import './styles.scss';

interface IWizardNavigationProps extends IAccentedComponent {
  formStep: Step,
  handlePrevious: () => void,
  handleNext: () => void,
  nextDisabled: boolean
}

const WizardNavigation: React.FC<IWizardNavigationProps> = (props) => {

  const {
    accentColor,
    formStep,
    handlePrevious,
    handleNext,
    nextDisabled
  } = props;

  const getPrevStepLabel = () => {

    //If first step of canvas form
    if(formStep.name === ECanvasSteps.TARGET_CUSTOMERS) {
      return <div className='font-weight-bold text-left'>Back to General Info</div>
    }

    const stepIndex = allSteps.indexOf(formStep.name);
    const stepLabel = stepLabels[allSteps[stepIndex-1]];

    return(
      <div className='d-flex'>
        <div className='font-weight-bold'>Step {padNumber(formStep.stepNumber-1)}:&nbsp;</div>
        <div>{stepLabel}</div>
      </div>
    );
  };

  const getNextStepLabel = () => {

    //If last step of General Info Form
    if(formStep.name === EGeneralInfoSteps.TOKEN_PLANNED) {
      return <div className='font-weight-bold text-left'>Let the Magic Begin!</div>
    }

    //If last step of Canvas Form
    if(formStep.name === ECanvasSteps.REVENUE_STREAMS) {
      return <div className='font-weight-bold text-left'>Do the Magic!</div>
    }

    const stepIndex = allSteps.indexOf(formStep.name);
    const stepLabel = stepLabels[allSteps[stepIndex+1]];

    return(
      <div className='d-flex'>
        <div className='font-weight-bold'>Step {padNumber(formStep.stepNumber+1)}:&nbsp;</div>
        <div>{stepLabel}</div>
      </div>
    )
  };

  const renderPrevBtn = allSteps.indexOf(formStep.name) > 0;

  return(
    <div className={`wizardNavigation ${renderPrevBtn ? 'justify-content-between' : 'justify-content-end'}`}>
      {
        renderPrevBtn &&
        <Button
          onClick={handlePrevious}
          label={getPrevStepLabel()}
          leftComponent={<ChevronGray/>}
          accentColor={ACCENT_COLOR.WHITE}
        />
      }

      <Button
        onClick={handleNext}
        label={getNextStepLabel()}
        rightComponent={<ChevronWhite/>}
        accentColor={accentColor}
        disabled={nextDisabled}
      />
    </div>
  )
};

export default WizardNavigation;
