import React, {MouseEvent, useEffect, useRef, useState} from 'react';
import {ReactComponent as MVPLogo} from '../../../Common/Assets/mvp-logo.svg';
import {ReactComponent as ChevronBlack} from '../../../Common/Assets/chevron-black.svg';
import {ReactComponent as DBMCLogo} from "../../../Common/Assets/dbmc-logo.svg";
import {ReactComponent as Close} from "../../../Common/Assets/close.svg";
import {ReactComponent as Hamburger} from "../../../Common/Assets/hamburger.svg";
import {Container} from "reactstrap";
import {StepStatus} from "../../Elements/StepStatus";
import {useDispatch, useSelector} from "react-redux";
import {prevStep} from "../../../Core/Redux/WizardSteps/wizardSteps.actions";
import {AppState} from "../../../Core/Redux/store";
import {getActiveStep} from "../../../Core/Redux/WizardSteps/wizardSteps.selectors";
import Separator from "../../Elements/Separator";
import {CSSTransition} from 'react-transition-group';
import {Routes} from "../../Pages";
import './styles.scss';

interface IWizardHeaderProps {
}

const WizardHeader: React.FC<IWizardHeaderProps> = () => {

  const dispatch = useDispatch();

  const headerRef = useRef<HTMLDivElement>(null);

  const [infoClosedStatus, setInfoClosedStatus] = useState(true);
  const [hamburgerClosed, setHamburgerClosed] = useState(true);
  const [wizardNavigation, setWizardNavigation] = useState(false);

  const activeStep = useSelector((state: AppState) => getActiveStep(state));
  const wizardSteps = useSelector((state: AppState) => state.wizardSteps.wizardSteps);

  useEffect(() => {

    const handleClickOutside = (ev: MouseEvent) => {
      if(!headerRef || !headerRef.current)
        return;

      // @ts-ignore
      if(!headerRef.current.contains(ev.target)){
        setInfoClosedStatus(true);
      }
    };

    // @ts-ignore
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // @ts-ignore
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [setInfoClosedStatus]);

  useEffect(() => {
    const currLocation = window.location.hash.substr(1,window.location.hash.length);

    if(currLocation === Routes.CANVAS_WIZARD) {
      setWizardNavigation(true)
    } else {
      setWizardNavigation(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  return(
    <div className={`wizardHeader ${!wizardNavigation && 'justify-content-center'}`} ref={headerRef}>

      {
        wizardNavigation &&
        <button className='bg-transparent d-xl-none' onClick={() => dispatch(prevStep())}>
          <div className='headerBtn'>
            <ChevronBlack className='fluidSvg rotate-270'/>
          </div>
        </button>
      }

      <button className='logoContainer' onClick={() => setInfoClosedStatus(prevState => !prevState)}>
        <MVPLogo className='fluidSvg'/>
      </button>

      {
        wizardNavigation &&
        <button className='bg-transparent d-xl-none' onClick={() => setHamburgerClosed(prevState => !prevState)}>
          <div className='headerBtn'>
            <Hamburger className='fluidSvg'/>
          </div>
        </button>
      }

      <div className={`mvpInfo ${infoClosedStatus && 'closed'}`}>
        <div className='content'>
          <h3 className='font-weight-bold'>Who are we?</h3>
          <div className='mvpDesc'>
            <p>
              We live and breathe decentralization as consultants, designers,
              developers, engineers and marketers - dedicated towards defining,
              building and growing your project as if it is our own.
            </p>

            <p> And we know how to get things done properly. </p>
          </div>

          <p className='mvpLinks'>
            Check out our website at <a href='https://mvpworkshop.co' target="_blank" rel='noopener noreferrer'>mvpworkshop.co</a>,
            or drop us a line at <a href="mailto:hello@mvpworkshop.co" target="_blank" rel='noopener noreferrer'>hello@mvpworkshop.co</a>
          </p>
        </div>
      </div>

      <CSSTransition
        in={!hamburgerClosed && wizardNavigation}
        timeout={350}
        classNames="hamburger-transition"
        unmountOnExit
      >
        <div className='hamburgerMenu d-xl-none'>
          <Container className='flex-grow-1 d-flex flex-column'>
            <div className='d-flex align-items-center'>
              <div className='dbmcLogo'>
                <DBMCLogo className='fluidSvg'/>
              </div>
              <div className='hamburgerTitle'>Decentralised Business Model Canvas</div>
              <button className='headerBtn flex-grow-1 justify-content-end d-flex' onClick={() => setHamburgerClosed(true)}>
                <Close/>
              </button>
            </div>
            <StepStatus wizardSteps={wizardSteps} activeStep={activeStep} className='flex-grow-1 justify-content-center'/>
          </Container>
          <div className='hamburgerFooter'>
            <Container>
              <div className='text-center'>
                <p className='fs-20 font-weight-bold text-white'>We are offering free consultation!</p>
                <p className='fs-16 text-white'>Get in touch at <a href="mailto:hello@mvpworkshop.co" target="_blank" rel='noopener noreferrer'>hello@mvpworkshop.co</a></p>
                <Separator className='separatorClass'/>
                <p className='text-gray'>Created with ♥︎ for the blockchain</p>
                <p className='text-gray'>by <a href="https://mvpworkshop.co" target="_blank" rel='noopener noreferrer'>MVP Workshop</a></p>
              </div>
            </Container>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
};

export default WizardHeader;
