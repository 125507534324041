import React from 'react';
import { Container } from 'reactstrap';

interface IPageProps {
  fullPage?: boolean
  className?: string
}

const Page: React.FC<React.PropsWithChildren<IPageProps>> = ({
  fullPage = false,
  className = '',
  ...otherProps
}) => {

  const {
    children
  } = otherProps;

  return (
    <Container fluid={fullPage} className={className}>
      {children}
    </Container>
  );
};

export default Page;
