import {Reducer} from 'redux';
import {
  CLEAR_WIZARD_STEPS_STATE,
  SET_ACTIVE_STEP,
  UPDATE_STEP_STATUS,
  WizardStepsActionTypes,
  WizardStepsState
} from './wizardSteps.types';
import {dbmcWizardSteps} from "../../../Common/Constants/Steps.constants";

const initialState: WizardStepsState = {
  activeStepName: dbmcWizardSteps[0].steps[0].name,
  wizardSteps: dbmcWizardSteps
};

export const wizardStepsReducer: Reducer<WizardStepsState, WizardStepsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_STEP: {
      return {
        ...state,
        activeStepName: action.payload.activeStepName
      };
    }

    case UPDATE_STEP_STATUS: {
      const {stepName, isFinished} = action.payload;

      return {
        ...state,
        wizardSteps: state.wizardSteps.map(wizardStep => {

          const newSteps = wizardStep.steps.map(step => {
            if(step.name === stepName)
              return {
                ...step,
                isFinished
              };

            return {...step}
          });

          return {
            ...wizardStep,
            steps: [...newSteps]
          }
        })
      }
    }

    case CLEAR_WIZARD_STEPS_STATE: {
      return {
        ...initialState
      }
    }

    default: {
      return state;
    }
  }
};

export default wizardStepsReducer;
