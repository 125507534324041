import React from 'react';
import './styles.scss';

interface ISeparatorProps {
  vertical?: boolean
  className?: string
}

const Separator: React.FC<ISeparatorProps> = (props) => {

  const {
    vertical,
    className
  } = props;

  return(
    <div className={`separator ${vertical ? 'vertical' : 'horizontal'} ${className}`} />
  )
};

export default  Separator;
