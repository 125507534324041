import axios from 'axios';
import {
  REACT_APP_INFURA_IPFS_API_VERSION,
  REACT_APP_INFURA_IPFS_API_URL,
  REACT_APP_EMAILJS_API_URL,
  REACT_APP_EMAILJS_API_VERSION
} from '../../Common/envConfig';
import {
  TimeoutError
} from '../../Common/Types/Error.types'

export const InfuraIpfsApi = axios.create({
  baseURL: `${REACT_APP_INFURA_IPFS_API_URL}/api/${REACT_APP_INFURA_IPFS_API_VERSION}`,
});

InfuraIpfsApi.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if(error.response) {
      if(error.response.status === 504) {
        return Promise.reject(new TimeoutError());
      }
    }

    return Promise.reject(error);
  }
);

export const EmailJSAPI = axios.create({
  baseURL: `${REACT_APP_EMAILJS_API_URL}/api/${REACT_APP_EMAILJS_API_VERSION}`
});

EmailJSAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if(error.response) {
    }

    return Promise.reject(error);
  }
);
