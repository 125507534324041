import React, {ReactNode} from 'react';
import {IAccentedComponent} from '../../../Common/Types/Common.types';
import './styles.scss';
import Loader from "../Loader";

export enum ButtonType {
 REGULAR,
  FLAT
}

interface IButtonProps extends Partial<IAccentedComponent> {
  onClick: () => any
  label?: string | ReactNode,
  type?: ButtonType,
  leftComponent?: ReactNode,
  rightComponent?: ReactNode,
  labelClassName?: string,
  className?: string,
  disabled?: boolean,
  centerContent?: boolean,
  loading?: boolean
}

const Button: React.FC<IButtonProps> = (props) => {

  const {
    type = ButtonType.REGULAR,
    accentColor,
    leftComponent,
    rightComponent,
    label,
    className,
    labelClassName,
    disabled,
    onClick,
    centerContent,
    loading
  } = props;

  const handleButtonClick = () => {
    if(disabled || loading)
      return;

    onClick();
  };

  const getButtonClass = () => {
    //Add ifs for additional button styles
    if(type === ButtonType.REGULAR)
      return 'btn-regular';
    if(type === ButtonType.FLAT)
      return 'btn-flat';

    //Regular button is the default style
    return 'btn-regular';
  };

  const cursorPointer = !disabled ?  'cursorPointer' : '';
  const disabledClass = disabled ? 'disabled' : '';
  const centerAlignment = centerContent ? 'justify-content-center' : '';

  //Apply style based on button type
  const btnClass = getButtonClass();

  return(
    <button
      className={`${btnClass} ${cursorPointer} ${disabledClass} ${accentColor} ${className} ${centerAlignment}`}
      onClick={handleButtonClick}
    >
      {loading && <Loader/>}
      {leftComponent && leftComponent}
      {label && <div className={`btn-label ${labelClassName}`}>{label}</div>}
      {props.children}
      {rightComponent && rightComponent}
    </button>
  )
};

export default Button;
