import React, {Fragment} from 'react'
import {IInputProps, IInputSelection} from '../../../Common/Types/Input.types';
import {Step} from '../../../Common/Types/Steps.types';
import InputHeader from "../../Elements/InputHeader";
import Separator from "../../Elements/Separator";
import StepIndicator from "../../Elements/StepIndicator";
import './styles.scss';

export type ButtonSelectionValueType = string;

export interface IButtonSelectionOption extends IInputSelection<ButtonSelectionValueType> {
}

interface IButtonSelectionProps extends IInputProps<IButtonSelectionOption> {
  options: IButtonSelectionOption[],
  formStep?: Step,
}

const RadioInput: React.FC<IButtonSelectionProps> = (props) => {

  const {
    label,
    value,
    name,
    labelClassName,
    options,
    onChange,
    formStep
  } = props;

  const handleOptionClick = (option: IButtonSelectionOption) => {
    onChange({value: option, name});
  };

  const renderOptions = () => {
    return options.map((option, index) => {
      return(
        <div className='d-flex flex-row flex-grow-1 flex-wrap flex-xl-nowrap' key={`${index}${option.value}`}>
          <div
            className={`optionContainer cursorPointer bg-white ${value.value === option.value ? 'clicked' : ''}`}
            onClick={() => handleOptionClick(option)}
          >
            {option.icon && <div className='optionIconContainer'><img src={option.icon} alt={option.label} className='fluidSvg'/></div>}
            <div className='optionLabel'>{option.label}</div>
          </div>
          {index !== options.length - 1 &&
            <Fragment>
              <Separator vertical={true} className='d-none d-xl-block'/>
              <Separator className='d-xl-none'/>
            </Fragment>
          }
        </div>
      )
    })
  };

  return(
    <div className='buttonSelection mvpInput'>
      {label && <InputHeader label={label} labelClassName={labelClassName}/>}
      <div className='d-flex flex-row options'>
        {renderOptions()}
        {formStep && <StepIndicator step={formStep} className='bg-white'/>}
      </div>
    </div>
  )
};

export default RadioInput;
