import React, {Fragment, useState, useEffect, KeyboardEvent} from 'react';
import {IInputProps} from '../../../Common/Types/Input.types';
import {IAccentedComponent} from '../../../Common/Types/Common.types';
import {Step} from '../../../Common/Types/Steps.types';
import Button from '../../Elements/Button';
import {Input} from 'reactstrap';
import StepIndicator from "../../Elements/StepIndicator";
import Separator from "../../Elements/Separator";
import InputHeader from "../../Elements/InputHeader";
import {ReactComponent as PlusIcon} from '../../../Common/Assets/plus.svg';
import './styles.scss'

export type MultiTextValueType = string[];

interface MultiTextInputProps extends IInputProps<MultiTextValueType>, IAccentedComponent {
  formStep: Step
}

const MultiTextInput: React.FC<MultiTextInputProps> = (props) => {

  const {
    value: values,
    placeholder,
    name,
    label,
    onChange,
    formStep,
    accentColor
  } = props;

  //Effect used to set state of remove btn for new inputs
  useEffect(() => {
    let newRemoveBtnState = [];
    for(let i=0; i<values.length; i++)
      newRemoveBtnState[i] = false;

    setRemoveBtnState(newRemoveBtnState)
  }, [values.length]);

  //State of remove buttons
  const [removeBtnState, setRemoveBtnState] = useState<boolean[]>([]);

  //Toggles remove btn state based on input index
  const toggleRemoveBtn = (index: number, value?: boolean) => {
    setRemoveBtnState((prevState) => {
      const newState = [...prevState];
      newState[index] = value === undefined ? !newState[index] : value;

      return newState;
    })
  };

  //Removes input based on its index
  const removeInput = (inputIndex:number) => {
    //Removes element from value state by its index
    const newValues = values.filter((item, index) => index !== inputIndex);
    onChange({name, value: newValues});
  };

  const handleInputChange = (inputIndex: number, newValue: string) => {
    const newValues = [...values];
    newValues[inputIndex] = newValue;

    onChange({name, value: newValues});
  };

  const handleAddNew = () => {
    const newValues = [...values];
    newValues.push('');

    onChange({name, value: newValues});
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if(e.key === 'Enter') {
      handleAddNew();
    }
  };

  const renderInputs = () => {
    return values.map((value, index) => {

      const isFirstElement = index === 0;
      const isLastElement = index === values.length-1;

      return(
        <Fragment key={index}>
          {/* Events are handled in the top div to prevent double events caused by newly rendered remove button*/}
          <div
            className={`d-flex flex-row bg-white inputWrapper ${accentColor}`}
            onMouseOver={() => toggleRemoveBtn(index, true)}
            onMouseLeave={() => toggleRemoveBtn(index, false)}
          >
            <Input
              value={value}
              type='text'
              placeholder={placeholder}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyPress={isLastElement ? handleKeyPress : () => {}}
              autoFocus={isLastElement}
            />

            {removeBtnState[index] && !isLastElement &&
              <div
                  className='removeBtn cursorPointer'
                  onClick={() => removeInput(index)}
              >
                Remove
              </div>
            }

            {isFirstElement && <StepIndicator step={formStep}/>}

            {isLastElement &&
              <Button
                onClick={handleAddNew}
                accentColor={accentColor}
                centerContent={true}
                className='addNewItemBtn'
              >
                <PlusIcon/>
              </Button>
            }
          </div>
          {!isLastElement && <Separator/>}
        </Fragment>
      )
    })
  };

  return(
    <div className='multiTextInput mvpInput'>
      {label && <InputHeader label={label}/>}
      {renderInputs()}
    </div>
  )
};

export default MultiTextInput;
