import React, {ReactNode, useEffect, useState} from 'react';
import {BrowserRouterProps, useHistory} from 'react-router-dom';
import Page from "../../Elements/Page";
import WizardHeader from "../../Components/WizardHeader";
import Button, {ButtonType} from "../../Elements/Button";
import {ReactComponent as ChevronWhite} from "../../../Common/Assets/chevron-white.svg";
import {ReactComponent as ChevronGray} from "../../../Common/Assets/chevron-gray.svg";
import {ReactComponent as ChevronRed} from "../../../Common/Assets/chevron-red.svg";
import {ACCENT_COLOR} from "../../../Common/Types/Common.types";
import {Routes} from "../index";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import './styles.scss'
import {useDispatch} from "react-redux";
import {generateTcPdf} from "../../../Core/Redux/Wizard/wizard.actions";

const modalImgs = [
  require('../../../Common/Assets/TokenCanvasModal/page1.png'),
  require('../../../Common/Assets/TokenCanvasModal/page1.png'),
  require('../../../Common/Assets/TokenCanvasModal/page2.png'),
  require('../../../Common/Assets/TokenCanvasModal/page3.png'),
];

const WizardBonusStepPage: React.FC<BrowserRouterProps> = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalPage, setModalPage] = useState(0);

  useEffect(() => {
    if(modalPage > 0){
      setModalOpen(true)
    } else {
      setModalOpen(false)
    }
  }, [modalPage]);

  const handleLeadOn = () => {
    setModalPage(1);
    setModalOpen(true);
  };

  const handleModalBack = () => {
    setModalPage(currPage => currPage-1)
  };

  const handleModalContinue = () => {
    if(modalPage === 3) {

      dispatch(generateTcPdf());
      history.push(Routes.WIZARD_DONE);

      return;
    }

    setModalPage(currPage => currPage+1)
  };

  const getModalText = (): ReactNode => {
    switch (modalPage) {
      case 1: {
        return "The Token Modelling Canvas will help you define your tokenomics model similarly to how the Canvases helped you define your (decentralized) business model."
      }
      case 2: {
        return "Some of the fields are the same in both canvases, and those will come prepopulated based on your Canvases. You should definitely check if you need to adjust for example the Proposed Solution or User/Customer Segments in your Token Modelling Canvas - your tokenomics model is a part of your business model, and as such might focus on different aspects and value streams when compared to the holistic overview of your project."
      }
      case 3: {
        return <span>Do not worry about filling out the rest of the canvas, as you will have enough tips and questions in the canvas to guide you - <span className='font-weight-bold'>and we will have an interactive tool released soon enough.</span></span>
      }
      default: {
        return ''
      }
    }
  };

  return(
    <Page fullPage={true} className='wizardBonusStepPage p-0'>
      <WizardHeader/>
      <div className='wizardBonusStepContainer'>
        <span className='heroHeadline'>Bonus Step!</span>
        <span className='descriptionText'>Since you plan to have a token, would you like to go through the Token Modelling Canvas as well?</span>
        <div className='stepBtnContainer'>
          <Button
            onClick={() => {history.push(Routes.WIZARD_DONE)}}
            accentColor={ACCENT_COLOR.WHITE}
            label="No, I am done."
            labelClassName='font-weight-bold'
          />
          <Button
            onClick={handleLeadOn}
            accentColor={ACCENT_COLOR.RED}
            label="Yes - lead on!"
            labelClassName='font-weight-bold'
            rightComponent={<ChevronWhite/>}
          />
        </div>

        <Modal
          centered={true}
          isOpen={modalOpen}
          toggle={() => setModalOpen(prevState => !prevState)}
          className='tokenCanvasModal'
        >
          <ModalHeader className='modalHeader d-xl-flex d-none'>
            <img src={modalImgs[modalPage]} className='modalHeaderIcon' alt='modalIcon'/>
          </ModalHeader>
          <ModalBody className='modalBody'>
            <p className='modalTitle'>Token Modelling Canvas</p>
            <p className='modalDescription'>
              {getModalText()}
            </p>
          </ModalBody>
          <ModalFooter className='modalFooter'>
            <Button
              type={ButtonType.FLAT}
              accentColor={ACCENT_COLOR.GRAY}
              label="Back"
              labelClassName='fontWeight-600'
              leftComponent={<ChevronGray/>}
              onClick={handleModalBack}
            />
            <Button
              type={ButtonType.FLAT}
              accentColor={ACCENT_COLOR.RED}
              label={modalPage === 3 ? 'Go To My Canvases' : 'Continue'}
              labelClassName='fontWeight-600'
              rightComponent={modalPage === 3 ? null : <ChevronRed />}
              onClick={handleModalContinue}
            />
          </ModalFooter>
        </Modal>
      </div>
    </Page>
  )
};

export default WizardBonusStepPage;
